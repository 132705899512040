import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AptosIcon = () => {
  const faAptos = {
    prefix: "fas" as IconPrefix,
    iconName: "fa-aptos" as IconName,
    icon: [
      500,
      500,
      [],
      "e001",
      "M387.07,172.13h-42.4c-4.94,0-9.64-2.12-12.91-5.81l-17.2-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.04,8.58H28.46c-6.61,18.84-10.92,38.74-12.64,59.38H234.92c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.56l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h178.84c-1.72-20.65-6.03-40.55-12.64-59.38h-95.46Z M148.4,356.39c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.55l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h242.36c9.08-18.76,15.73-38.89,19.69-59.98h-232.63c-4.94,0-9.64-2.12-12.91-5.81l-17.19-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.05,8.58H19.12c3.96,21.09,10.62,41.22,19.69,59.98h109.59Z M320.34,107.24c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.56l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h46.09C403.94,48.9,334.13,11.16,255.49,11.16S107.04,48.9,63.15,107.24H320.34Z M227.77,415.83h-63.03c-4.94,0-9.64-2.12-12.91-5.81l-17.19-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.05,8.58h-.98c43.91,47.05,106.44,76.5,175.87,76.5s131.95-29.45,175.87-76.5H227.77Z",
    ],
  };
  return <FontAwesomeIcon icon={faAptos} />;
};
