import {
  getGetPolicyWalletsQueryKey,
  useListGroups,
  useListUsers,
  useGetPolicyWallets,
  useUpdatePolicyWallet,
} from "@api/__generated__/mainservice";
import {
  Title,
  Button,
  Modal,
  SelectField,
  RadioGroup,
  Loader,
} from "@components/common";
import styles from "./AddUserToWallet.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { getUserIcon } from "@utils/transformers";
import { useSnackbar } from "notistack";

interface AddUserToWalletForm {
  type: string;
  id: string;
}

const AddUserToWallet = () => {
  const navigate = useNavigate();
  const { walletId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { data: walletPolicies, isLoading } = useGetPolicyWallets(
    walletId ?? "",
  );
  const { data: users } = useListUsers();
  const { data: groups } = useListGroups();
  const { mutate: updateWalletAccess, isPending } = useUpdatePolicyWallet({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetPolicyWalletsQueryKey(walletId ?? ""),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to update wallet access.", {
          variant: "error",
        });
      },
    },
  });

  const methods = useForm<AddUserToWalletForm>({
    resolver: yupResolver(
      Yup.object({
        id: Yup.string().trim().required("Choose a user"),
        type: Yup.string().required(),
      }),
    ),
    defaultValues: {
      id: "",
      type: "USER",
    },
    mode: "onChange",
  });

  const onSubmit = (values: AddUserToWalletForm) => {
    if (walletId)
      updateWalletAccess({ walletId, data: { allow: [values.id] } });
  };

  const type = methods.watch("type");

  const options = useMemo(() => {
    const policies = walletPolicies ?? [];
    if (type === "USER" && users) {
      return users
        .filter(
          (x) =>
            !policies.some((y) =>
              y.members.some((z) => z.user_id === x.user_id),
            ),
        )
        .map((x) => {
          const icon = getUserIcon(x.type);
          return {
            value: x.user_id,
            label: (
              <div className={styles.selectItem}>
                <div className={styles.selectItemIcon}>{icon}</div>
                {x.name}
              </div>
            ),
          };
        });
    }
    if (type === "GROUP" && groups) {
      return groups
        .filter((x) => !policies.some((y) => y.group_id === x.group_id))
        .map((x) => {
          const icon = getUserIcon("GROUP");
          return {
            value: x.group_id,
            label: (
              <div className={styles.selectItem}>
                <div className={styles.selectItemIcon}>{icon}</div>
                {x.name}
              </div>
            ),
          };
        });
    }
    return [];
  }, [users, groups, type, walletPolicies]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal
      className={styles.card}
      parentRoute={`/wallets?wallet_id=${walletId}`}
    >
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Title underlined>Update Wallet</Title>
          <div className={styles.formContent}>
            <RadioGroup
              className={styles.radioInputContainer}
              name="type"
              options={[
                { value: "USER", label: "User" },
                { value: "GROUP", label: "Group" },
              ]}
            />
            <SelectField
              btnClassName={styles.selectInput}
              itemClassName={styles.selectInputItem}
              name="id"
              options={options}
              placeholder={type === "USER" ? "Choose user" : "Choose group"}
            />
            <Button
              disabled={isPending}
              className={styles.submitBtn}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddUserToWallet;
