import { ReactNode } from "react";
import styles from "./SideBarButton.module.css";
import { Button } from "@components/common";
import classNames from "classnames/bind";
import { useNavigate } from "react-router";

const cx = classNames.bind(styles);

interface SideBarButtonProps {
  icon?: ReactNode;
  selected: boolean;
  value: string;
  pathTo?: string;
  condensed?: boolean;
}

const SideBarButton = ({
  icon,
  selected,
  value,
  pathTo,
  condensed = false,
}: SideBarButtonProps) => {
  const navigate = useNavigate();

  const classes = cx(
    styles.sidebarBtn,
    selected ? "selected" : undefined,
    condensed ? styles.condensed : undefined,
  );

  return (
    <Button
      variant="text"
      className={classes}
      onClick={() => {
        if (pathTo) {
          navigate(pathTo);
        }
      }}
    >
      <div className={styles.icon}>{icon}</div>
      {value}
    </Button>
  );
};

export default SideBarButton;
