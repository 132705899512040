import { Title, Text, Button, Modal } from "@components/common";
import styles from "./AddCustodianApiKey.module.css";
import { TextField } from "@components/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import {
  ApiCredentialSchema,
  getGetCustodiansQueryKey,
  useAddCustodianApiCredential,
} from "@api/__generated__/custodyrouter";
import {
  getCustodianConnectMsg,
  getCustodianName,
} from "@utils/constants/custodian";

const AddCustodianApiKey = () => {
  const { custodianId } = useParams();
  console.log(custodianId);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: connectCustodian, isPending } = useAddCustodianApiCredential({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetCustodiansQueryKey(),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to connect Custodian.", { variant: "error" });
      },
    },
  });

  const methods = useForm<ApiCredentialSchema>({
    resolver: yupResolver(
      Yup.object({
        value: Yup.string().trim().required("The API key is required"),
      }),
    ),
    defaultValues: {
      value: "",
    },
    mode: "onChange",
  });

  const onSubmit = (values: ApiCredentialSchema) => {
    console.log(custodianId);
    if (custodianId)
      connectCustodian({ custodianId: custodianId, data: values });
  };

  return (
    <Modal className={styles.card}>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Title underlined>Connect {getCustodianName(custodianId)}</Title>
          <div className={styles.formContent}>
            <Text>{getCustodianConnectMsg(custodianId)}</Text>
            <TextField
              variant="underlined"
              className={styles.textInput}
              placeholder="API Key"
              name="value"
            />
            <Button
              disabled={isPending}
              className={styles.submitBtn}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddCustodianApiKey;
