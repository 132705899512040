/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Main Service API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { ChainSchema, SuccessFlagSchema } from ".././schemas";
import { customInstance } from "../../../api";
import type { ErrorType } from "../../../api";

/**
 * @summary Gets a list of supported chains
 */
export const listChains = (signal?: AbortSignal) => {
  return customInstance<ChainSchema[]>({
    url: `/chains`,
    method: "GET",
    signal,
  });
};

export const getListChainsQueryKey = () => {
  return [`/chains`] as const;
};

export const getListChainsQueryOptions = <
  TData = Awaited<ReturnType<typeof listChains>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof listChains>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListChainsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listChains>>> = ({
    signal,
  }) => listChains(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listChains>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListChainsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listChains>>
>;
export type ListChainsQueryError = ErrorType<void>;

export function useListChains<
  TData = Awaited<ReturnType<typeof listChains>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof listChains>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof listChains>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useListChains<
  TData = Awaited<ReturnType<typeof listChains>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof listChains>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof listChains>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useListChains<
  TData = Awaited<ReturnType<typeof listChains>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof listChains>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Gets a list of supported chains
 */

export function useListChains<
  TData = Awaited<ReturnType<typeof listChains>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof listChains>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListChainsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary [INTERNAL] Update chain information
 */
export const updateChains = () => {
  return customInstance<SuccessFlagSchema>({ url: `/chains`, method: "PATCH" });
};

export const getUpdateChainsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateChains>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateChains>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateChains>>,
    void
  > = () => {
    return updateChains();
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateChainsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateChains>>
>;

export type UpdateChainsMutationError = ErrorType<void>;

/**
 * @summary [INTERNAL] Update chain information
 */
export const useUpdateChains = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateChains>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateChains>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getUpdateChainsMutationOptions(options);

  return useMutation(mutationOptions);
};
