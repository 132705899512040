import { PropsWithChildren, ReactElement, ReactNode } from "react";
import styles from "./ChipItem.module.css";
import { ClearIcon } from "@components/icons";
import { IconButton } from "@components/common";

export interface ChipItemProps {
  onRemove: () => void;
  startIcon?: ReactNode;
}

export const ChipItem = ({
  onRemove,
  startIcon,
  ...props
}: PropsWithChildren<ChipItemProps>): ReactElement => {
  return (
    <div className={styles.item}>
      {startIcon}
      {props.children}
      <IconButton className={styles.btn} onClick={onRemove}>
        <ClearIcon />
      </IconButton>
    </div>
  );
};
