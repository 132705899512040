import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "./InfoIcon.module.css";

interface InfoIconProps {
  hoverElement?: JSX.Element;
}

export const InfoIcon = ({ hoverElement }: InfoIconProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      className={styles.iconContainer}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <FontAwesomeIcon icon={faCircleInfo} />
      {showTooltip && hoverElement && (
        <div className={styles.icon}>{hoverElement}</div>
      )}
    </div>
  );
};
