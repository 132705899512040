import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
  InputHTMLAttributes,
} from "react";
import classNames from "classnames/bind";

import styles from "./Button.module.css";

const cx = classNames.bind(styles);

export const buttonVariants = {
  text: "btn-text",
  outlined: "btn-outlined",
  contained: "btn-contained",
};

/** Button Props */
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Controls the Button style:
   * `contained` -> Default solid Button with background color.
   * `outlined` -> A subtle variation of the Button component with a transparent background color that highlights the action text.
   * `text` -> A less prominent variation of the Button component that highlights hover interactions.
   * @default contained
   */
  variant?: keyof typeof buttonVariants;
  /**
   *  If `true`, the button will be displayed using the mini-button styles
   */
  mini?: boolean;
  /**
   * Controls the Button state. Mirrors the HTMLButtonElement `disabled` property.
   */
  disabled?: boolean;
  /**
   * If `true`, the button will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  href?: string;
} & (
    | ({
        as: "input";
      } & InputHTMLAttributes<HTMLInputElement>)
    | ({
        as: "a";
        /**
         * The URL to link to when the button is clicked.
         * If defined, an `a` element will be used as the root node.
         */
        href?: string;
        rel?: string | undefined;
        target?: string | undefined;
      } & AnchorHTMLAttributes<HTMLAnchorElement>)
    | ({
        as?: "button" | undefined;
      } & ButtonHTMLAttributes<HTMLButtonElement>)
    | ({
        as?: "span" | undefined;
      } & HTMLAttributes<HTMLSpanElement>)
  );

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLInputElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const {
    variant = "contained",
    mini = false,
    fullWidth,
    as = "button",
    className,
    href,
    rel,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    target,
    onClick,
    type = "button",
    disabled = false,
    ...htmlProps
  } = props;

  const classes = cx(
    styles.btn,
    buttonVariants[variant],
    mini ? styles["btn-min"] : undefined,
    fullWidth ? "fullWidth" : undefined,
    disabled ? "disabled" : undefined,
    className,
  );

  return React.createElement(
    as ?? "button",
    {
      ...htmlProps,
      className: classes,
      href,
      rel,
      target,
      ref,
      disabled,
      onClick,
      type,
    },
    props.children,
  );
});

Button.displayName = "Button";
