import {
  NavigationButton,
  Title,
  SearchBar,
  Selector,
  PopoverMenu,
  Toggle,
  Text,
} from "@components/common";
import { FilterIcon, GlobeIcon, PlusIcon } from "@components/icons";
import styles from "./WalletHeader.module.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useListChains, WalletSchema } from "@api/__generated__/mainservice";
import { Wallet } from "./Wallets";
import { getChainIcon, chainIdNames } from "@utils/transformers";
import { getCustodianLogoSrc } from "@utils/constants/custodian";

export interface WalletQuery {
  field: keyof WalletSchema;
  value: string;
}

interface Props {
  filterWallets: (wallets: Wallet[]) => void;
  allWallets: Wallet[];
}

const WalletHeader = ({ allWallets, filterWallets }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [queryChain, setQueryChain] = useState<string>("all");
  const [queryChainL2, setQueryChainL2] = useState<string>("all");
  const [querySearch, setQuerySearch] = useState("");
  const [queryState, setQueryState] = useState<"active" | "inactive" | "any">(
    "active",
  );
  const [queryCustodians, setQueryCustodians] = useState<string[]>([
    "cd-000",
    "cd-001",
  ]);

  const handleToggleActive = (value: boolean) => {
    const newState = value ? "any" : "inactive";
    const wallets = getFilteredWallets(
      queryChain,
      querySearch,
      newState,
      queryCustodians,
      queryChainL2,
    );
    if (wallets.length === 1) {
      setSearchParams({ wallet_id: wallets[0].id });
    } else {
      setSearchParams();
    } // clear query params when searching
    filterWallets(wallets);

    setQueryState(newState);
  };

  const handleToggleInactive = (value: boolean) => {
    const newState = value ? "any" : "active";
    const wallets = getFilteredWallets(
      queryChain,
      querySearch,
      newState,
      queryCustodians,
      queryChainL2,
    );
    if (wallets.length === 1) {
      setSearchParams({ wallet_id: wallets[0].id });
    } else {
      setSearchParams();
    } // clear query params when searching
    filterWallets(wallets);

    setQueryState(newState);
  };

  const getFilteredWallets = (
    chainQuery: string,
    searchQuery: string,
    stateQuery: string,
    custodianQuery: string[],
    chainQueryL2: string,
  ) => {
    if (allWallets && allWallets.length > 0) {
      let wallets: Wallet[] = allWallets;

      // filter on chain
      if (chainQuery !== "all") {
        wallets = wallets.filter((wallet) => wallet.chain.id === chainQuery);
      }

      if (chainQuery === "ch-60" && chainQueryL2 !== "all") {
        console.log({ wallets });
        wallets = wallets.filter((wallet) => {
          console.log({ chains: wallet.walletOptions?.chains });

          return wallet.walletOptions?.chains?.includes(chainQueryL2);
        });
        console.log({ wallets });
      }

      // filter on state
      if (stateQuery !== "any") {
        wallets = wallets.filter((wallet) => wallet.state === stateQuery);
      }

      // filter on search
      if (searchQuery !== "any") {
        wallets = wallets.filter((wallet) => {
          return (
            wallet.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
            wallet.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            wallet.id.toLowerCase().includes(searchQuery.toLowerCase())
          );
        });
      }

      // filter on custodian
      if (custodianQuery.length !== 0) {
        wallets = wallets.filter((wallet) =>
          custodianQuery.includes(wallet.custodianId),
        );
      }

      return wallets;
    }
    return allWallets;
  };

  const handleSearch = (search: string) => {
    const wallets = getFilteredWallets(
      queryChain,
      search,
      queryState,
      queryCustodians,
      queryChainL2,
    );
    if (wallets.length === 1) {
      setSearchParams({ wallet_id: wallets[0].id });
    } else {
      setSearchParams();
    } // clear query params when searching
    filterWallets(wallets);

    // set state so it remembers for other filter params
    setQuerySearch(search);
  };

  const handleSelect = (value: string) => {
    const wallets = getFilteredWallets(
      value,
      querySearch,
      queryState,
      queryCustodians,
      queryChainL2,
    );
    if (wallets.length === 1) {
      setSearchParams({ wallet_id: wallets[0].id });
    } else {
      setSearchParams();
    } // clear query params when searching
    filterWallets(wallets);

    // set state so it remembers for other filter params
    setQueryChain(value);
  };
  const handleSelectL2s = (value: string) => {
    const wallets = getFilteredWallets(
      queryChain,
      querySearch,
      queryState,
      queryCustodians,
      value,
    );
    if (wallets.length === 1) {
      setSearchParams({ wallet_id: wallets[0].id });
    } else {
      setSearchParams();
    } // clear query params when searching
    filterWallets(wallets);

    // set state so it remembers for other filter params
    setQueryChainL2(value);
  };

  const { data: chainData } = useListChains();
  const chains = useMemo(() => {
    const chainOptions = [
      {
        value: "all",
        label: (
          <div className={styles.selectItem}>
            <div className={styles.selectItemIcon}>
              <GlobeIcon />
            </div>
            All Chains
          </div>
        ),
      },
    ];
    if (chainData) {
      return chainOptions.concat(
        chainData?.map((x) => {
          const icon = getChainIcon(x.name);
          return {
            value: x.chain_id,
            label: (
              <div className={styles.selectItem}>
                <div className={styles.selectItemIcon}>{icon}</div>
                {x.name}
              </div>
            ),
          };
        }),
      );
    }
    return chainOptions;
  }, [chainData]);

  const evm_l2s = useMemo(() => {
    const chainOptions = [
      {
        value: "all",
        label: (
          <div className={styles.selectItem}>
            <div className={styles.selectItemIcon}>
              <GlobeIcon />
            </div>
            All Chains
          </div>
        ),
      },
    ];

    let evm_l2s: string[] = [];

    const chain = chainData ?? [];

    for (let i = 0; i < chain.length; i++) {
      if (chain[i].chain_id == "ch-60") {
        evm_l2s = chain[i].supported_options?.["chains"];
      }
    }

    if (chainData) {
      return chainOptions.concat(
        evm_l2s?.map((x) => {
          const icon = getChainIcon(chainIdNames[x]);
          return {
            value: x,
            label: (
              <div className={styles.selectItem}>
                <div className={styles.selectItemIcon}>{icon}</div>
                {chainIdNames[x]}
              </div>
            ),
          };
        }),
      );
    }
    return chainOptions;
  }, [chainData]);

  useEffect(() => {
    const queryParam = searchParams.entries().next().value;
    if (allWallets && allWallets.length > 0) {
      if (queryParam) {
        let queryKey = queryParam[0];
        if (queryKey === "wallet_id") queryKey = "id";
        const queryField = queryKey as keyof Wallet;
        const queryValue = queryParam[1];
        const result = allWallets.filter((wallet) => {
          const value = wallet[queryField] as string;
          if (!value) return true; //not a string so we cannot filter atm
          return value.toLowerCase().includes(queryValue.toLowerCase());
        });
        filterWallets(result);
      } else {
        // apply the default filters
        const result = getFilteredWallets(
          queryChain,
          querySearch,
          queryState,
          queryCustodians,
        );
        filterWallets(result);
      }
    }
  }, [allWallets]);

  const handleToggleCustodian = (select: boolean, custodianId: string) => {
    const custodianFilter = select
      ? [...queryCustodians, custodianId]
      : queryCustodians.filter((x) => x !== custodianId);

    const wallets = getFilteredWallets(
      queryChain,
      querySearch,
      queryState,
      custodianFilter,
    );
    if (wallets.length === 1) {
      setSearchParams({ wallet_id: wallets[0].id });
    } else {
      setSearchParams();
    } // clear query params when searching
    filterWallets(wallets);

    setQueryCustodians(custodianFilter);
  };

  // hard coded for now, should be usememo from result GET /custody
  const custodianOptions = [
    {
      label: <div className={styles.filterMenuLabel}>Custody</div>,
    },
    {
      label: (
        <Toggle
          className={styles.filterMenuOption}
          label={
            <div className={styles.custodianOption}>
              <img
                className={styles.custodianLogo}
                src={getCustodianLogoSrc("cd-000")}
              />
              <Text>CrypDefi</Text>
            </div>
          }
          isDisabled={
            queryCustodians.length === 1 && queryCustodians[0] === "cd-000"
          }
          onChange={(value) => handleToggleCustodian(value, "cd-000")}
          value={queryCustodians.includes("cd-000")}
        />
      ),
    },
    {
      label: (
        <Toggle
          className={styles.filterMenuOption}
          label={
            <div className={styles.custodianOption}>
              <img
                className={styles.custodianLogo}
                src={getCustodianLogoSrc("cd-001")}
                alt="custodian logo"
              />
              <Text>Fordefi</Text>
            </div>
          }
          isDisabled={
            queryCustodians.length === 1 && queryCustodians[0] === "cd-001"
          }
          onChange={(value) => handleToggleCustodian(value, "cd-001")}
          value={queryCustodians.includes("cd-001")}
        />
      ),
    },
  ];

  return (
    <div className={styles.headerContainer}>
      <Title className={styles.title}>Wallets</Title>
      <div className={styles.actionContainer}>
        <div className={styles.searchContainer}>
          <Selector
            onSelect={handleSelect}
            placeholder=""
            options={chains}
            value={queryChain}
            variant="shadow"
            btnClassName={styles.selectBtn}
            itemClassName={styles.selectOption}
            boxClassName={styles.selectBox}
            containerClassName={styles.selectContainer}
          />
          {queryChain == "ch-60" && (
            <Selector
              onSelect={handleSelectL2s}
              placeholder=""
              options={evm_l2s}
              value={queryChainL2}
              variant="shadow"
              btnClassName={styles.selectBtn}
              itemClassName={styles.selectOption}
              boxClassName={styles.selectBox}
              containerClassName={styles.selectContainer}
            />
          )}
          <SearchBar
            className={styles.searchBar}
            onChange={handleSearch}
            onSearch={handleSearch}
            value={querySearch}
            placeholder="Search name, address or wallet id"
          />
          <PopoverMenu
            id="wallet-header"
            options={[
              {
                label: <div className={styles.filterMenuLabel}>State</div>,
              },
              {
                label: (
                  <Toggle
                    className={styles.filterMenuOption}
                    label="Active wallets"
                    isDisabled={queryState === "active"}
                    onChange={handleToggleActive}
                    value={queryState === "active" || queryState === "any"}
                  />
                ),
              },
              {
                label: (
                  <Toggle
                    className={styles.filterMenuOption}
                    label="Inactive wallets"
                    isDisabled={queryState === "inactive"}
                    onChange={handleToggleInactive}
                    value={queryState === "inactive" || queryState === "any"}
                  />
                ),
              },
              ...custodianOptions,
            ]}
            icon={<FilterIcon />}
          />
        </div>
        <NavigationButton
          startIcon={<PlusIcon />}
          pathTo="add"
          className={styles.addWalletBtn}
          keepBackground={true}
        >
          Add wallet
        </NavigationButton>
      </div>
    </div>
  );
};

export default WalletHeader;
