import { ReactElement } from "react";

import styles from "./List.module.css";
import { DefaultListObjectType, ListProps } from "./List.types";
import { Loader } from "../loader/Loader";
import { EmptyState } from "../emptystate";
import { ListItem } from "./ListItem";
import classNames from "classnames";

/**
 * Table component
 * @param params With all properties  from [[ListProps]]
 * @returns ReactElement
 */
export const List = <ListObjectType extends DefaultListObjectType>({
  header,
  actions,
  columns,
  content,
  data,
  rowHeight = 52,
  isLoading,
  variant = "outlined",
  size = "large",
  type = "primary",
  className,
}: ListProps<ListObjectType>): ReactElement => {
  return (
    <div
      className={classNames(styles.listContainer, className, {
        [styles.outlined]: variant === "outlined",
        [styles.contained]: variant === "contained",
        [styles.outlinedFree]: variant === "outlined-free",
        [styles.primary]: type === "primary",
        [styles.secondary]: type === "secondary",
      })}
    >
      {header}
      {isLoading ? (
        <Loader size={size} />
      ) : (
        <>
          {data.length ? (
            data.map((row) => (
              <ListItem
                height={rowHeight}
                row={row}
                columns={columns}
                actionColumn={actions}
                content={content}
                variant={variant}
                type={type}
                numberOfItems={data.length}
                key={row.id}
              />
            ))
          ) : (
            <EmptyState size={size} title={"No Data"} />
          )}
        </>
      )}
    </div>
  );
};
