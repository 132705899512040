import {
  AddWalletRequestBodySchema,
  AddWalletRequestBodySchemaOptions,
  getListWalletsQueryKey,
  useCreateWallet,
  useListChains,
} from "@api/__generated__/mainservice";
import {
  Title,
  Button,
  Modal,
  SelectField,
  Selector,
  HorizontalDivider,
  BoldText,
  NavigationButton,
  TextField,
} from "@components/common";
import styles from "./AddWallet.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { getChainIcon, snakeToTitleCase } from "@utils/transformers";
import { useSnackbar } from "notistack";
import { getCustodianLogoSrc } from "@utils/constants/custodian";
import { useGetCustodians } from "@api/__generated__/custodyrouter";
import classNames from "classnames";

const AddWallet = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [options, setOptions] = useState<AddWalletRequestBodySchemaOptions>();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createWallet, isPending } = useCreateWallet({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getListWalletsQueryKey(),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to add wallet.", { variant: "error" });
      },
    },
  });

  const { data } = useListChains();
  const chains = useMemo(() => {
    if (data) {
      return data?.map((x) => {
        const icon = getChainIcon(x.name);
        return {
          value: x.chain_id,
          label: (
            <div className={styles.selectItem}>
              <div className={styles.selectItemIcon}>{icon}</div>
              {x.name}
            </div>
          ),
        };
      });
    }
    return [];
  }, [data]);

  const { data: custodians } = useGetCustodians();
  const activeCustodians = useMemo(() => {
    if (custodians) {
      return custodians.filter((c) => c.activated);
    }
    return [];
  }, [custodians]);

  const methods = useForm<AddWalletRequestBodySchema>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().trim().required("Name is required"),
        chain_id: Yup.string().required("Choose a chain"),
      }),
    ),
    defaultValues: {
      name: "",
      chain_id: "",
    },
    mode: "onChange",
  });

  const onSubmit = (values: AddWalletRequestBodySchema) => {
    createWallet({ data: { ...values, options: options } });
  };

  const setDefaultOptions = (chainId: string) => {
    if (!data) return;
    const defaulOptiont = data.find(
      (x) => x.chain_id === chainId,
    )?.default_options;
    if (!defaulOptiont) return;
    setOptions(defaulOptiont);
  };

  const handleSelectOption = (option: string, value: string) => {
    if (!options) return;
    setOptions((prevOptions) => {
      const copy = { ...prevOptions };
      copy[option] = value;
      return copy;
    });
  };

  const selectedChain = methods.watch("chain_id");

  const optionOptions = useMemo(() => {
    if (data && selectedChain) {
      const options = data.find(
        (x) => x.chain_id === selectedChain,
      )?.supported_options;
      if (!options) return undefined;
      return Object.keys(options).map((option) => ({
        key: option,
        values: options[option].map((value) => ({
          value: value,
          label: value,
        })),
      }));
    }
    return undefined;
  }, [data, selectedChain]);

  return (
    <Modal className={styles.card} parentRoute="/wallets">
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Title underlined>Add Wallet</Title>
          <div className={styles.formContent}>
            <TextField
              variant="underlined"
              className={styles.textInput}
              placeholder="Name"
              name="name"
            />
            <SelectField
              onSelect={setDefaultOptions}
              btnClassName={styles.selectInput}
              itemClassName={styles.selectInputItem}
              name="chain_id"
              options={chains}
              placeholder="Choose Network"
            />
            {optionOptions &&
              optionOptions.map((option) => (
                <Selector
                  key={option.key}
                  disabled={option.values.length <= 1}
                  onSelect={(selectedOption) =>
                    handleSelectOption(option.key, selectedOption)
                  }
                  value={options ? options[option.key] : undefined}
                  btnClassName={styles.selectInput}
                  itemClassName={styles.selectInputItem}
                  options={option.values}
                  placeholder={snakeToTitleCase(option.key)}
                />
              ))}
            <Button
              disabled={isPending}
              className={styles.submitBtn}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
      {activeCustodians.length > 0 && (
        <>
          <HorizontalDivider className={styles.divider}>
            or import
          </HorizontalDivider>
          <div
            className={classNames(styles.grid, {
              [styles.gridOne]: activeCustodians.length === 1,
              [styles.gridTwo]: activeCustodians.length === 2,
            })}
          >
            {activeCustodians &&
              activeCustodians.map((custodian) => (
                <NavigationButton
                  key={custodian.id}
                  pathTo={custodian.id}
                  variant="text"
                  className={styles.gridItem}
                >
                  <img
                    className={styles.logo}
                    src={getCustodianLogoSrc(custodian.id)}
                    alt="logo"
                  />
                  <BoldText className={styles.title}>{custodian.name}</BoldText>
                </NavigationButton>
              ))}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddWallet;
