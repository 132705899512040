import { PropsWithChildren } from "react";

import styles from "./Modal.module.css";
import classNames from "classnames";
import { NavigationButton } from "../button/NavigationButton";
import { ClearIcon } from "@components/icons";
import { useLocation, useNavigate } from "react-router";

interface Props {
  className?: string;
  parentRoute?: string;
}

export const Modal = ({
  children,
  parentRoute,
  className,
}: PropsWithChildren<Props>) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickOutsideModal = (event: React.MouseEvent<HTMLDivElement>) => {
    const container = document.getElementById(`Modal-${location.pathname}`);
    if (event.target === container) {
      if (parentRoute) navigate(parentRoute);
      else navigate(-1);
    }
  };

  return (
    <div
      className={styles.modalOverlay}
      onClick={handleClickOutsideModal}
      id={`Modal-${location.pathname}`}
    >
      <div className={classNames(styles.modalContent, className)}>
        <NavigationButton
          className={styles.closeBtn}
          startIcon={<ClearIcon />}
          pathTo={parentRoute ?? "-1"}
        ></NavigationButton>
        {children}
      </div>
    </div>
  );
};
