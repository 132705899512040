import { useListWallets } from "@api/__generated__/mainservice";
import {
  Title,
  Button,
  Modal,
  SelectField,
  RadioGroup,
  NumberField,
} from "@components/common";
import styles from "./AssetTransfer.module.css";
import { Text } from "@components/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useMemo, useState } from "react";
import { getChainIcon } from "@utils/transformers";
import { useSnackbar } from "notistack";
import {
  useGetCustodianWallet,
  useRequestTransactionToWallet,
} from "@api/__generated__/custodyrouter";

interface TransferForm {
  toWallet: string;
  amount?: string;
  asset: string;
  priority: string;
}

const AssetTransfer = () => {
  const { walletId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [network, setNetwork] = useState<string>();

  const { data: wallet } = useGetCustodianWallet(walletId ?? "");

  const { data: wallets } = useListWallets(
    {
      nativeOnly: true,
      chainId: wallet?.chain.chain_id,
      state: "active",
    },
    { query: { enabled: Boolean(wallet) } },
  );

  const { mutate: requestTransfer } = useRequestTransactionToWallet({
    mutation: {
      onSuccess: async () => {
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to request transfer.", { variant: "error" });
      },
    },
  });

  const methods = useForm<TransferForm>({
    resolver: yupResolver(
      Yup.object({
        toWallet: Yup.string()
          .trim()
          .required("Destination wallet is required"),
        priority: Yup.string().trim().required("Select priority"),
        asset: Yup.string().required("Select asset"),
      }),
    ),
    defaultValues: {
      toWallet: "",
      priority: "Medium",
      asset: "",
    },
    mode: "onChange",
  });

  const onSubmit = (values: TransferForm) => {
    if (walletId && network) {
      const data = {
        walletId: walletId,
        data: {
          to_wallet_id: values.toWallet,
          priority: values.priority as "Low" | "Medium" | "High",
          network: network,
          asset: values.asset,
          amount: isNaN(parseFloat(values.amount ?? ""))
            ? undefined
            : parseFloat(values.amount ?? ""),
        },
      };
      console.log(data);
      requestTransfer(data);
    }
  };

  const walletOptions = useMemo(() => {
    if (wallets) {
      return wallets.map((w) => ({
        label: (
          <div className={styles.walletOptionContainer}>
            {getChainIcon(w.chain.name)}
            <div>
              <Text className={styles.walletOptionName}>{w.name}</Text>
              <Text className={styles.walletOptionAddr}>
                {w.address.slice(0, 10) + " ...... " + w.address.slice(-10)}
              </Text>
            </div>
          </div>
        ),
        value: w.wallet_id,
      }));
    }
    return [];
  }, [wallets]);

  const assetOptions = useMemo(() => {
    if (wallet && wallet?.assets) {
      return wallet.assets.map((a) => ({
        label: (
          <div className={styles.assetOptionContainer}>
            <div>
              <Text className={styles.assetOptionTicker}>{a.ticker}</Text>
              <Text className={styles.assetOptionNet}>{a.network}</Text>
            </div>
            <div>
              <Text className={styles.assetOptionValue}>{a.value}</Text>
              <Text className={styles.assetOptionBalance}>
                {a.balance?.amount} {a.balance?.currency}
              </Text>
            </div>
          </div>
        ),
        value: a.asset_id,
      }));
    }
    return [];
  }, [wallet]);

  const selectedAsset = methods.watch("asset");
  const maxAmount = useMemo(() => {
    if (selectedAsset && wallet && wallet?.assets) {
      const asset = wallet.assets.find((a) => a.asset_id === selectedAsset);
      return asset?.value;
    }
  }, [selectedAsset, wallet]);

  const handleSelectAsset = (assetId: string) => {
    if (wallet && wallet?.assets && wallet.assets.length > 0) {
      setNetwork(wallet.assets.find((x) => x.asset_id === assetId)?.network);
    }
    methods.resetField("amount");
  };

  return (
    <Modal className={styles.card} parentRoute="/wallets">
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Title underlined>Asset Transfer</Title>
          <div className={styles.formContent}>
            <SelectField
              btnClassName={styles.selectInput}
              itemClassName={styles.selectInputItem}
              name="toWallet"
              options={walletOptions}
              placeholder="Destination"
            />
            <SelectField
              onSelect={handleSelectAsset}
              btnClassName={styles.selectInput}
              itemClassName={styles.selectInputItem}
              name="asset"
              options={assetOptions}
              placeholder="Asset"
            />
            <div className={styles.priorityContainer}>
              <Text className={styles.priorityLabel}>Priority</Text>
              <RadioGroup
                className={styles.radioInputContainer}
                name="priority"
                options={[
                  { value: "Low", label: "Low" },
                  { value: "Medium", label: "Medium" },
                  { value: "High", label: "High" },
                ]}
              />
            </div>

            <NumberField
              disabled={!selectedAsset}
              variant="underlined"
              className={styles.textInput}
              placeholder="Amount"
              name="amount"
              min={0}
              max={maxAmount}
            />
            <Button
              className={styles.submitBtn}
              type="submit"
              variant="contained"
            >
              Request Transfer
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AssetTransfer;
