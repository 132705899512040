/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Main Service API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  DeletePolicyResponseSchema,
  GetPolicyGroup200,
  GetPolicyUserParams,
  GroupSchema,
  PatchPolicyRequestSchema,
  PatchPolicyResponseSchema,
  WalletSchema,
} from ".././schemas";
import { customInstance } from "../../../api";
import type { ErrorType } from "../../../api";

/**
 * @summary Returns all wallets the user has access to though individual or group relations
 */
export const getPolicyUser = (
  userId: string,
  params?: GetPolicyUserParams,
  signal?: AbortSignal,
) => {
  return customInstance<WalletSchema[]>({
    url: `/policies/users/${userId}`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetPolicyUserQueryKey = (
  userId: string,
  params?: GetPolicyUserParams,
) => {
  return [`/policies/users/${userId}`, ...(params ? [params] : [])] as const;
};

export const getGetPolicyUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getPolicyUser>>,
  TError = ErrorType<void>,
>(
  userId: string,
  params?: GetPolicyUserParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyUser>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPolicyUserQueryKey(userId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPolicyUser>>> = ({
    signal,
  }) => getPolicyUser(userId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPolicyUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPolicyUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPolicyUser>>
>;
export type GetPolicyUserQueryError = ErrorType<void>;

export function useGetPolicyUser<
  TData = Awaited<ReturnType<typeof getPolicyUser>>,
  TError = ErrorType<void>,
>(
  userId: string,
  params: undefined | GetPolicyUserParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyUser>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPolicyUser>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPolicyUser<
  TData = Awaited<ReturnType<typeof getPolicyUser>>,
  TError = ErrorType<void>,
>(
  userId: string,
  params?: GetPolicyUserParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyUser>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPolicyUser>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPolicyUser<
  TData = Awaited<ReturnType<typeof getPolicyUser>>,
  TError = ErrorType<void>,
>(
  userId: string,
  params?: GetPolicyUserParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyUser>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Returns all wallets the user has access to though individual or group relations
 */

export function useGetPolicyUser<
  TData = Awaited<ReturnType<typeof getPolicyUser>>,
  TError = ErrorType<void>,
>(
  userId: string,
  params?: GetPolicyUserParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyUser>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPolicyUserQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates which wallets the user has direct access to
 */
export const updatePolicyUser = (
  userId: string,
  patchPolicyRequestSchema: PatchPolicyRequestSchema,
) => {
  return customInstance<PatchPolicyResponseSchema>({
    url: `/policies/users/${userId}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: patchPolicyRequestSchema,
  });
};

export const getUpdatePolicyUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePolicyUser>>,
    TError,
    { userId: string; data: PatchPolicyRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePolicyUser>>,
  TError,
  { userId: string; data: PatchPolicyRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePolicyUser>>,
    { userId: string; data: PatchPolicyRequestSchema }
  > = (props) => {
    const { userId, data } = props ?? {};

    return updatePolicyUser(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePolicyUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePolicyUser>>
>;
export type UpdatePolicyUserMutationBody = PatchPolicyRequestSchema;
export type UpdatePolicyUserMutationError = ErrorType<void>;

/**
 * @summary Updates which wallets the user has direct access to
 */
export const useUpdatePolicyUser = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePolicyUser>>,
    TError,
    { userId: string; data: PatchPolicyRequestSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePolicyUser>>,
  TError,
  { userId: string; data: PatchPolicyRequestSchema },
  TContext
> => {
  const mutationOptions = getUpdatePolicyUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Revokes direct access for the user
 */
export const deletePolicyUser = (userId: string) => {
  return customInstance<DeletePolicyResponseSchema>({
    url: `/policies/users/${userId}`,
    method: "DELETE",
  });
};

export const getDeletePolicyUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyUser>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePolicyUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePolicyUser>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return deletePolicyUser(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePolicyUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePolicyUser>>
>;

export type DeletePolicyUserMutationError = ErrorType<void>;

/**
 * @summary Revokes direct access for the user
 */
export const useDeletePolicyUser = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyUser>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePolicyUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions = getDeletePolicyUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns all wallets the group has access to
 */
export const getPolicyGroup = (groupId: string, signal?: AbortSignal) => {
  return customInstance<GetPolicyGroup200>({
    url: `/policies/groups/${groupId}`,
    method: "GET",
    signal,
  });
};

export const getGetPolicyGroupQueryKey = (groupId: string) => {
  return [`/policies/groups/${groupId}`] as const;
};

export const getGetPolicyGroupQueryOptions = <
  TData = Awaited<ReturnType<typeof getPolicyGroup>>,
  TError = ErrorType<void>,
>(
  groupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyGroup>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPolicyGroupQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPolicyGroup>>> = ({
    signal,
  }) => getPolicyGroup(groupId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!groupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPolicyGroup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPolicyGroupQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPolicyGroup>>
>;
export type GetPolicyGroupQueryError = ErrorType<void>;

export function useGetPolicyGroup<
  TData = Awaited<ReturnType<typeof getPolicyGroup>>,
  TError = ErrorType<void>,
>(
  groupId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyGroup>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPolicyGroup>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPolicyGroup<
  TData = Awaited<ReturnType<typeof getPolicyGroup>>,
  TError = ErrorType<void>,
>(
  groupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyGroup>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPolicyGroup>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPolicyGroup<
  TData = Awaited<ReturnType<typeof getPolicyGroup>>,
  TError = ErrorType<void>,
>(
  groupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyGroup>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Returns all wallets the group has access to
 */

export function useGetPolicyGroup<
  TData = Awaited<ReturnType<typeof getPolicyGroup>>,
  TError = ErrorType<void>,
>(
  groupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPolicyGroup>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPolicyGroupQueryOptions(groupId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates which wallets the group has access to
 */
export const updatePolicyGroup = (
  groupId: string,
  patchPolicyRequestSchema: PatchPolicyRequestSchema,
) => {
  return customInstance<PatchPolicyResponseSchema>({
    url: `/policies/groups/${groupId}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: patchPolicyRequestSchema,
  });
};

export const getUpdatePolicyGroupMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePolicyGroup>>,
    TError,
    { groupId: string; data: PatchPolicyRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePolicyGroup>>,
  TError,
  { groupId: string; data: PatchPolicyRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePolicyGroup>>,
    { groupId: string; data: PatchPolicyRequestSchema }
  > = (props) => {
    const { groupId, data } = props ?? {};

    return updatePolicyGroup(groupId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePolicyGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePolicyGroup>>
>;
export type UpdatePolicyGroupMutationBody = PatchPolicyRequestSchema;
export type UpdatePolicyGroupMutationError = ErrorType<void>;

/**
 * @summary Updates which wallets the group has access to
 */
export const useUpdatePolicyGroup = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePolicyGroup>>,
    TError,
    { groupId: string; data: PatchPolicyRequestSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePolicyGroup>>,
  TError,
  { groupId: string; data: PatchPolicyRequestSchema },
  TContext
> => {
  const mutationOptions = getUpdatePolicyGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Revokes all access for the group
 */
export const deletePolicyGroup = (groupId: string) => {
  return customInstance<DeletePolicyResponseSchema>({
    url: `/policies/groups/${groupId}`,
    method: "DELETE",
  });
};

export const getDeletePolicyGroupMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyGroup>>,
    TError,
    { groupId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePolicyGroup>>,
  TError,
  { groupId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePolicyGroup>>,
    { groupId: string }
  > = (props) => {
    const { groupId } = props ?? {};

    return deletePolicyGroup(groupId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePolicyGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePolicyGroup>>
>;

export type DeletePolicyGroupMutationError = ErrorType<void>;

/**
 * @summary Revokes all access for the group
 */
export const useDeletePolicyGroup = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyGroup>>,
    TError,
    { groupId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePolicyGroup>>,
  TError,
  { groupId: string },
  TContext
> => {
  const mutationOptions = getDeletePolicyGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns all users and groups that have access to the wallet
 */
export const getPolicyWallets = (walletId: string, signal?: AbortSignal) => {
  return customInstance<GroupSchema[]>({
    url: `/policies/wallets/${walletId}`,
    method: "GET",
    signal,
  });
};

export const getGetPolicyWalletsQueryKey = (walletId: string) => {
  return [`/policies/wallets/${walletId}`] as const;
};

export const getGetPolicyWalletsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPolicyWallets>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPolicyWallets>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPolicyWalletsQueryKey(walletId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPolicyWallets>>
  > = ({ signal }) => getPolicyWallets(walletId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!walletId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPolicyWallets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPolicyWalletsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPolicyWallets>>
>;
export type GetPolicyWalletsQueryError = ErrorType<void>;

export function useGetPolicyWallets<
  TData = Awaited<ReturnType<typeof getPolicyWallets>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPolicyWallets>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPolicyWallets>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPolicyWallets<
  TData = Awaited<ReturnType<typeof getPolicyWallets>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPolicyWallets>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPolicyWallets>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPolicyWallets<
  TData = Awaited<ReturnType<typeof getPolicyWallets>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPolicyWallets>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Returns all users and groups that have access to the wallet
 */

export function useGetPolicyWallets<
  TData = Awaited<ReturnType<typeof getPolicyWallets>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPolicyWallets>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPolicyWalletsQueryOptions(walletId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates which users and groups have access to the wallet
 */
export const updatePolicyWallet = (
  walletId: string,
  patchPolicyRequestSchema: PatchPolicyRequestSchema,
) => {
  return customInstance<PatchPolicyResponseSchema>({
    url: `/policies/wallets/${walletId}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: patchPolicyRequestSchema,
  });
};

export const getUpdatePolicyWalletMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePolicyWallet>>,
    TError,
    { walletId: string; data: PatchPolicyRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePolicyWallet>>,
  TError,
  { walletId: string; data: PatchPolicyRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePolicyWallet>>,
    { walletId: string; data: PatchPolicyRequestSchema }
  > = (props) => {
    const { walletId, data } = props ?? {};

    return updatePolicyWallet(walletId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePolicyWalletMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePolicyWallet>>
>;
export type UpdatePolicyWalletMutationBody = PatchPolicyRequestSchema;
export type UpdatePolicyWalletMutationError = ErrorType<void>;

/**
 * @summary Updates which users and groups have access to the wallet
 */
export const useUpdatePolicyWallet = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePolicyWallet>>,
    TError,
    { walletId: string; data: PatchPolicyRequestSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePolicyWallet>>,
  TError,
  { walletId: string; data: PatchPolicyRequestSchema },
  TContext
> => {
  const mutationOptions = getUpdatePolicyWalletMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Revokes all access to the wallet
 */
export const deletePolicyWallet = (walletId: string) => {
  return customInstance<DeletePolicyResponseSchema>({
    url: `/policies/wallets/${walletId}`,
    method: "DELETE",
  });
};

export const getDeletePolicyWalletMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyWallet>>,
    TError,
    { walletId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePolicyWallet>>,
  TError,
  { walletId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePolicyWallet>>,
    { walletId: string }
  > = (props) => {
    const { walletId } = props ?? {};

    return deletePolicyWallet(walletId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePolicyWalletMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePolicyWallet>>
>;

export type DeletePolicyWalletMutationError = ErrorType<void>;

/**
 * @summary Revokes all access to the wallet
 */
export const useDeletePolicyWallet = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyWallet>>,
    TError,
    { walletId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePolicyWallet>>,
  TError,
  { walletId: string },
  TContext
> => {
  const mutationOptions = getDeletePolicyWalletMutationOptions(options);

  return useMutation(mutationOptions);
};
