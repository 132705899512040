import {
  getListWalletsQueryKey,
  useEditWallet,
  useGetWallet,
} from "@api/__generated__/mainservice";
import { Title, Button, Modal, Text } from "@components/common";
import styles from "./DeactivateWallet.module.css";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

const DeactivateWallet = () => {
  const { walletId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: wallet } = useGetWallet(walletId ?? "");

  const { mutate: editWallet, isPending } = useEditWallet({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getListWalletsQueryKey(),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to deactivate wallet.", { variant: "error" });
      },
    },
  });

  const handleDeactivateWallet = () => {
    if (walletId) {
      editWallet({
        walletId: walletId,
        data: {
          state: "inactive",
        },
      });
    }
  };

  return (
    <Modal className={styles.card}>
      <Title underlined>Deactivate Wallet</Title>
      <div className={styles.content}>
        <Text className={styles.warning}>
          Are you sure you want to deactivate this wallet:
        </Text>
        <div className={styles.details}>
          <Text>Name: {wallet?.name}</Text>
          <Text>Chain: {wallet?.chain.name}</Text>
          <Text>ID: {walletId}</Text>
        </div>
        <Button
          disabled={isPending}
          className={styles.submitBtn}
          type="button"
          variant="contained"
          onClick={() => handleDeactivateWallet()}
        >
          Deactivate
        </Button>
      </div>
    </Modal>
  );
};

export default DeactivateWallet;
