import {
  CopyText,
  EmptyState,
  List,
  ListColumnType,
  Loader,
  Text,
} from "@components/common";
import styles from "./WalletData.module.css";
import { useMemo } from "react";
import classNames from "classnames";
import {
  AssetSchema,
  useGetCustodianWallet,
} from "@api/__generated__/custodyrouter";
import { InfoIcon } from "@components/icons";

interface WalletDataProps {
  walletId?: string;
  nestedType?: "primary" | "secondary";
}

interface WalletToken extends AssetSchema {
  id: string;
}

const LinkedWalletData = ({
  walletId,
  nestedType = "secondary",
}: WalletDataProps) => {
  const { data: wallet, isLoading } = useGetCustodianWallet(walletId ?? "");

  const assets: WalletToken[] = useMemo(() => {
    if (!wallet || !wallet.assets) return [];
    return wallet.assets.map((w) => ({ ...w, id: w.asset_id }));
  }, [wallet]);

  const getSymbolCurrency = (currency = "") => {
    switch (currency.trim().toLowerCase()) {
      case "usd":
        return "$";
      case "eur":
        return "€";
      default:
        return currency.trim().toLowerCase();
    }
  };

  const tokenColumns: ListColumnType<WalletToken>[] = [
    {
      field: "ticker",
      headerName: "Token",
      width: "30%",
    },
    {
      field: "price",
      headerName: "Price",
      width: "40%",
      valueFormatter: (params) =>
        `${params.data?.price} ${getSymbolCurrency(params.data?.balance?.currency)}`,
    },
    {
      field: "value",
      headerName: "Amount",
      width: "30%",
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  if (!wallet) {
    return <EmptyState title="Linked wallet not found" />;
  }
  const balanceDisclaimer = () => {
    const hoverText = (
      <>
        <b className={styles.boldDisclaimer}>Disclaimer:</b> Balances and fiat
        conversions displayed are for informational purposes only and may not
        reflect precise values due to reliance on an external service provider.
        While we strive for accuracy, these figures should not be used as the
        sole basis for trading decisions. Before taking any business critical
        decision, we recommend consulting an additional service for
        verification.
      </>
    );
    return <InfoIcon hoverElement={hoverText} />;
  };

  return (
    <div className={styles.walletData}>
      <div className={styles.grid}>
        {wallet && (
          <div className={styles.col}>
            <Text className={styles.labelTitle}>Wallet Details</Text>
            <div
              className={classNames(styles.walletDetails, {
                [styles.primary]: nestedType === "primary",
                [styles.secondary]: nestedType === "secondary",
              })}
            >
              <div>
                <div className={styles.label}>Name</div>
                <div className={styles.value}>{wallet.name}</div>
              </div>
              <div>
                <div className={styles.label}>Wallet ID</div>
                <div className={styles.value}>{wallet.internal_wallet_id}</div>
              </div>
              <div>
                <div className={styles.label}>External Wallet ID</div>
                <div className={styles.value}>{wallet.external_wallet_id}</div>
              </div>
              <div>
                <div className={styles.label}>Address</div>
                <CopyText
                  className={styles.value}
                  confirmationMessage="Wallet address copied to clipboard!"
                >
                  {wallet.address}
                </CopyText>
              </div>
            </div>
          </div>
        )}
        <div className={styles.col}>
          <Text className={styles.labelTitle}>
            <>Token Info {balanceDisclaimer()}</>
          </Text>
          <List
            className={styles.walletTokens}
            variant="contained"
            columns={tokenColumns}
            data={assets}
            rowHeight={40}
            size="small"
            type={nestedType}
          />
        </div>
      </div>
    </div>
  );
};

export default LinkedWalletData;
