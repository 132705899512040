import {
  getListGroupsQueryKey,
  useEditGroup,
  useGetGroup,
} from "@api/__generated__/mainservice";
import { Title, Button, Modal, EmptyState, Loader } from "@components/common";
import styles from "./EditGroup.module.css";
import { TextField } from "@components/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

interface EditGroupForm {
  name: string;
}

const EditGroup = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: editGroup, isPending } = useEditGroup({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getListGroupsQueryKey(),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to update group.", { variant: "error" });
      },
    },
  });

  const { data: group, isLoading } = useGetGroup(groupId ?? "");

  const methods = useForm<EditGroupForm>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().trim().required("Name is required"),
        email: Yup.string().trim(),
        public_key: Yup.string().trim(),
        type: Yup.string().trim(),
      }),
    ),
    defaultValues: {
      name: "",
    },
    mode: "onChange",
  });

  const onSubmit = (values: EditGroupForm) => {
    if (groupId) editGroup({ groupId: groupId, data: { name: values.name } });
  };

  useEffect(() => {
    if (group) {
      methods.reset({
        name: group.name,
      });
    }
  }, [group, methods]);

  if (isLoading) {
    return <Loader />;
  }

  if (!group) {
    return (
      <Modal className={styles.card}>
        <EmptyState size="medium" title={"No group found with this id"} />
      </Modal>
    );
  }

  return (
    <Modal className={styles.card}>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Title underlined>Edit Group</Title>
          <div className={styles.formContent}>
            <TextField
              variant="underlined"
              className={styles.textInput}
              placeholder="Name"
              name="name"
            />
            <Button
              disabled={isPending}
              className={styles.submitBtn}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditGroup;
