import { ReactElement, ReactNode } from "react";
import styles from "./Toggle.module.css";
import classNames from "classnames";
import { Text } from "../text";

interface Props {
  className?: string;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
  value: boolean;
  label?: ReactNode;
}

export const Toggle = ({
  value,
  onChange,
  isDisabled,
  className,
  label,
}: Props): ReactElement => {
  return (
    <div className={classNames(styles.container, className)}>
      <Text className={styles.label}>{label}</Text>
      <div
        className={classNames(styles.toggle, {
          [styles.disabled]: isDisabled,
          [styles.inactive]: !value,
        })}
        onClick={() => {
          if (!isDisabled) onChange(!value);
        }}
      >
        <div
          className={classNames(styles.toggleBall, { [styles.active]: value })}
        />
      </div>
    </div>
  );
};
