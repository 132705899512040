import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./Input.module.css";

export const InputVariants = {
  outlined: "outlined",
  underlined: "underlined",
  naked: "naked",
};
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  endAdornment?: ReactNode;
  endAdornmentClassName?: string;
  startAdornment?: ReactNode;
  startAdornmentClassName?: string;
  error?: boolean;
  isDisabled?: boolean;
  variant?: keyof typeof InputVariants;
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      startAdornment,
      startAdornmentClassName,
      endAdornment,
      endAdornmentClassName,
      error,
      isDisabled,
      variant = "naked",
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={classNames(
          styles.inputContainer,
          rest.className,
          styles[variant],
          {
            [styles.errorInputField]: error,
            [styles.inputWithStartIcon]: startAdornment,
            [styles.inputWithEndIcon]: endAdornment,
            [styles.disabledInputField]: isDisabled,
          },
        )}
      >
        {startAdornment && (
          <div
            className={classNames(
              startAdornmentClassName,
              styles.startAdornment,
            )}
          >
            {startAdornment}
          </div>
        )}
        <input {...rest} className={styles.field} ref={ref} />
        {endAdornment && (
          <div
            className={classNames(endAdornmentClassName, styles.endAdornment)}
          >
            {endAdornment}
          </div>
        )}
      </div>
    );
  },
);
