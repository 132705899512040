import {
  getListUsersQueryKey,
  useDeleteUser,
  useGetUser,
} from "@api/__generated__/mainservice";
import { Title, Button, Modal, Text } from "@components/common";
import styles from "./DeleteUser.module.css";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

const DeleteUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user } = useGetUser(userId ?? "");

  const { mutate: deleteUser, isPending } = useDeleteUser({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getListUsersQueryKey(),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to delete user.", { variant: "error" });
      },
    },
  });

  const handleDeleteUser = () => {
    if (userId) deleteUser({ userId: userId });
  };

  return (
    <Modal className={styles.card}>
      <Title underlined>Delete User</Title>
      <div className={styles.content}>
        <Text className={styles.warning}>
          Are you sure you want to delete this user:
        </Text>
        <div className={styles.details}>
          <Text>Type: {user?.type}</Text>
          <Text>Name: {user?.name}</Text>
          <Text>ID: {userId}</Text>
        </div>
        <Button
          disabled={isPending}
          className={styles.submitBtn}
          type="button"
          variant="contained"
          onClick={() => handleDeleteUser()}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteUser;
