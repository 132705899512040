import {
  CreateGroupBody,
  getListGroupsQueryKey,
  useCreateGroup,
} from "@api/__generated__/mainservice";
import { Title, Button, Modal } from "@components/common";
import styles from "./AddGroup.module.css";
import { TextField } from "@components/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

const AddGroup = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createGroup, isPending } = useCreateGroup({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getListGroupsQueryKey(),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to add group.", { variant: "error" });
      },
    },
  });

  const methods = useForm<CreateGroupBody>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().trim().required("Name is required"),
      }),
    ),
    defaultValues: {
      name: "",
    },
    mode: "onChange",
  });

  const onSubmit = (values: CreateGroupBody) => {
    createGroup({ data: values });
  };

  return (
    <Modal className={styles.card}>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Title underlined>Add Group</Title>
          <div className={styles.formContent}>
            <TextField
              variant="underlined"
              className={styles.textInput}
              placeholder="Name"
              name="name"
            />
            <Button
              disabled={isPending}
              className={styles.submitBtn}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddGroup;
