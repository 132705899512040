import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RadixIcon = () => {
  const faRadix = {
    prefix: "fas" as IconPrefix,
    iconName: "fa-radix" as IconName,
    icon: [
      400,
      400,
      [],
      "e001",
      "M170.912 306.613C166.762 306.613 162.817 304.63 160.343 301.216L108.688 229.63H75V203.567H115.352C119.542 203.567 123.467 205.57 125.92 208.964L168.132 267.447L232.543 120.309C234.628 115.566 239.309 112.5 244.481 112.5H325V138.563H253.005L182.85 298.804C180.969 303.097 176.942 306.04 172.281 306.531C171.852 306.592 171.382 306.613 170.912 306.613Z",
    ],
  };
  return <FontAwesomeIcon icon={faRadix} />;
};
