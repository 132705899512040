import classnames from "classnames";

import styles from "./EmptyState.module.css";
import Lottie from "react-lottie";
import noData from "@assets/lotties/nodata-lottie.json";
import { Title, Text } from "../text";

const sizeVariants = {
  small: "small",
  medium: "medium",
  large: "large",
};

const sizeMap = new Map<keyof typeof sizeVariants, number>([
  ["small", 100],
  ["medium", 200],
  ["large", 400],
]);

interface Props {
  title: string;
  description?: string;
  className?: string;
  size?: keyof typeof sizeVariants;
}

export const EmptyState = ({
  size = "large",
  title,
  description,
  className,
}: Props) => {
  return (
    <div className={classnames(styles.container, className)}>
      <Title size={size} className={styles.title}>
        {title}
      </Title>
      <Text>{description}</Text>
      <Lottie
        height={sizeMap.get(size)}
        width={sizeMap.get(size)}
        options={{
          loop: true,
          autoplay: true,
          animationData: noData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
      />
    </div>
  );
};
