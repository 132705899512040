import classnames from "classnames";

import styles from "./PopoverMenu.module.css";
import { ReactNode, useEffect, useState } from "react";
import { SettingsIcon } from "@components/icons";
import classNames from "classnames";
import { IconButton } from "../button/IconButton";
import { random } from "lodash";

interface MenuOption {
  label: ReactNode;
  onClick?: () => void;
  className?: string;
}

interface Props {
  icon?: ReactNode;
  options: MenuOption[];
  id?: string;
  align?: "left" | "bottom" | "right" | "auto";
}

export const PopoverMenu = ({
  icon = <SettingsIcon />,
  options,
  id = random().toString(),
  align = "auto",
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth - 56); // subtract padding
  const [alignmentClass, setAlignmentClass] = useState(align);

  useEffect(() => {
    const popover = document.getElementById(`PopoverMenu-${id}-popover`);

    if (align === "auto") {
      if (popover) {
        const { right, width } = popover.getBoundingClientRect();

        if (alignmentClass === "right" && right > windowSize) {
          if (right - width / 2 <= windowSize) setAlignmentClass("bottom");
          else setAlignmentClass("left");
          return;
        }
        if (alignmentClass === "bottom") {
          if (right + width / 2 <= windowSize) setAlignmentClass("right");
          else if (right > windowSize) setAlignmentClass("left");
          return;
        }
        if (alignmentClass === "left") {
          if (right + width <= windowSize) setAlignmentClass("right");
          else if (right + width / 2 <= windowSize) setAlignmentClass("bottom");
          return;
        }
        if (alignmentClass === "auto") {
          if (right + width <= windowSize) setAlignmentClass("right");
          else if (right + width / 2 <= windowSize) setAlignmentClass("bottom");
          else setAlignmentClass("left");
          return;
        }
      }
    } else {
      setAlignmentClass(align);
      return;
    }
  }, [align, windowSize]);

  const handleClickOption = (option: MenuOption) => {
    if (option.onClick) option.onClick();
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth - 56);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  document.addEventListener(
    "click",
    function handleClickOutside(event: MouseEvent) {
      const menuContainer = document.getElementById(`PopoverMenu-${id}`);
      if (
        open &&
        event.target &&
        menuContainer &&
        !menuContainer.contains(event.target as HTMLElement)
      ) {
        setOpen(false);
      }
    },
  );

  return (
    <div id={`PopoverMenu-${id}`} className={styles.container}>
      <IconButton
        className={styles.btn}
        onClick={() => setOpen((prev) => !prev)}
      >
        {icon}
      </IconButton>
      <div
        id={`PopoverMenu-${id}-popover`}
        className={classNames(styles.popover, {
          [styles.open]: open,
          [styles.left]: alignmentClass === "left",
          [styles.right]: alignmentClass === "right",
          [styles.bottom]: alignmentClass === "bottom",
        })}
      >
        {open && (
          <div className={styles.popoverContent}>
            {options.map((option, index) => (
              <div
                onClick={() => handleClickOption(option)}
                key={`PopoverMenu-${id}-item-${index}`}
                className={classnames(styles.item, option.className)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
