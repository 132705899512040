import React from "react";
import classNames from "classnames/bind";

import styles from "./IconButton.module.css";

const cx = classNames.bind(styles);
export const buttonColors = {
  primary: "btn-primary",
  secondary: "btn-secondary",
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: "primary" | "secondary";
  disabled?: boolean;
  className?: string;
};

export const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      color = "primary",
      disabled = false,
      ...htmlProps
    } = props;

    const classes = cx(
      styles.btn,
      buttonColors[color],
      disabled ? "disabled" : undefined,
      className,
    );

    return React.createElement(
      "button",
      { className: classes, ref, disabled, ...htmlProps },
      props.children,
    );
  },
);
