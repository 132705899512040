"use client";

import React, { useRef } from "react";
import classNames from "classnames";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

import { InputMessage } from "../InputMessage";
import styles from "./TextArea.module.css";
import { MultilineInput } from "../MultilineInput";

export interface TextAreaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  description?: string;
  errorMessage?: string;
  showLabel?: boolean;
}

export const InnerTextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(({ className, ...rest }, forwardedRef) => {
  const internalRef = useRef<HTMLTextAreaElement | null>(null);
  const ref = forwardedRef || internalRef;

  return (
    <div
      className={classNames(styles.inputContainer, className, {
        [styles.disabled]: rest.disabled,
      })}
    >
      <div className={styles.inputField}>
        <MultilineInput
          legend={rest.showLabel ? rest.placeholder : undefined}
          className={classNames({ [styles.inputError]: rest.errorMessage })}
          variant="outlined"
          {...rest}
          ref={ref}
        />
      </div>
      <InputMessage message={rest.description} />
      <InputMessage
        className={styles.inputMessage}
        variant="error"
        message={rest.errorMessage}
      />
    </div>
  );
});

export const TextArea = ({
  name,
  ...otherProps
}: UseControllerProps & TextAreaProps) => {
  const context = useFormContext();
  if (context === undefined || context === null) {
    throw new Error(
      "TextField must be used within a FormProvider from react-hook-form",
    );
  }

  const { control } = context;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <InnerTextArea
            {...otherProps}
            {...field}
            errorMessage={
              fieldState.error
                ? otherProps.errorMessage || fieldState.error.message
                : undefined
            }
            showLabel={field.value}
          />
        );
      }}
    />
  );
};

TextArea.displayName = "TextArea";
