import Lottie from "react-lottie";
import wipAnimation from "@assets/lotties/workinprogress-lottie.json";
import { Title } from "../text";
import styles from "./WorkInProgress.module.css";

const sizeVariants = {
  small: "small",
  medium: "medium",
  large: "large",
};

const sizeMap = new Map<keyof typeof sizeVariants, number>([
  ["small", 200],
  ["medium", 400],
  ["large", 600],
]);

interface Props {
  size?: keyof typeof sizeVariants;
}

export const WorkInProgress = ({ size = "large" }: Props) => {
  return (
    <>
      <Title size="xlarge" className={styles.title}>
        Work In Progress
      </Title>
      <Lottie
        height={sizeMap.get(size)}
        width={sizeMap.get(size)}
        options={{
          loop: true,
          autoplay: true,
          animationData: wipAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
      />
    </>
  );
};
