import { ReactElement, ReactNode } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import RadioButton from "./RadioButton";

export interface RadioOption {
  value: string;
  label: ReactNode;
  disabled?: boolean;
}

export interface RadioGroupProps {
  options: RadioOption[];
  className?: string;
  defaultOption?: number;
}

export const RadioGroup = ({
  name,
  options,
  className,
  ...rest
}: UseControllerProps & RadioGroupProps): ReactElement => {
  const context = useFormContext();

  const handleSelect = (option: RadioOption) => {
    context.setValue(name, option.value);
  };

  return (
    <Controller
      control={context.control}
      name={name}
      render={({ ...field }) => (
        <div className={className}>
          {options.map((option) => (
            // eslint-disable-next-line
            <RadioButton
              onClick={() => handleSelect(option)}
              name={name}
              id={option.value}
              {...field}
              {...rest}
              checked={field.field.value === option.value}
            >
              {option.label}
            </RadioButton>
          ))}
        </div>
      )}
    />
  );
};
