import { InputHTMLAttributes, PropsWithChildren } from "react";
import styles from "./RadioButton.module.css";
import classNames from "classnames";

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  disabled?: boolean;
  name: string;
}

const RadioButton = ({
  children,
  id,
  disabled = false,
  ...rest
}: PropsWithChildren<RadioButtonProps>) => {
  return (
    <label
      className={classNames(styles.radioLabel, { [styles.disabled]: disabled })}
    >
      <input id={id} type="radio" disabled={disabled} {...rest} />
      {children}
    </label>
  );
};

export default RadioButton;
