import { useMemo } from "react";
import { User } from "../Users";
import { useGetPolicyUser } from "@api/__generated__/mainservice";
import {
  CopyText,
  List,
  ListActionType,
  ListColumnType,
  ListItem,
  NavigationButton,
  Text,
} from "@components/common";
import styles from "./UserData.module.css";
import { getChainIcon } from "@utils/transformers";
import { RedirectIcon } from "@components/icons";

interface UserDataProps {
  user?: User;
}

interface Wallet {
  id: string;
  chain: string;
  name: string;
  address: string;
}

const UserData = ({ user }: UserDataProps) => {
  const { data, isLoading } = useGetPolicyUser(user?.id ?? "");

  const rows: Wallet[] = useMemo(() => {
    if (!data) return [];
    return data.map((row) => ({
      id: row.wallet_id,
      chain: row.chain.name,
      name: row.name,
      address: row.address,
    }));
  }, [data]);

  const walletColumns: ListColumnType<Wallet>[] = [
    {
      field: "chain",
      headerName: "",
      width: "40px",
      textAlign: "center",
      renderCell: (params) => getChainIcon(params.data?.chain ?? ""),
    },
    {
      field: "name",
      headerName: "Name",
      width: "35%",
    },
    {
      field: "address",
      headerName: "Address",
      width: "60%",
      renderCell: (params) => (
        <CopyText
          className={styles.walletAddress}
          copyValue={params.data?.address}
          confirmationMessage="Wallet address copied to clipboard!"
        >
          {params.data?.address.slice(0, 10) +
            " ...... " +
            params.data?.address.slice(-10)}
        </CopyText>
      ),
    },
  ];

  const userColumns: ListColumnType<User>[] = [
    {
      width: "calc((100% - 40px) * 0.35 + 40px)",
      field: "user_id",
      headerName: "User ID",
    },
    {
      width: "30%",
      field: "name",
      headerName: "Name",
    },
    {
      width: "30%",
      field: user?.type === "TRADER" ? "email" : "public_key",
      headerName: user?.type === "TRADER" ? "Email" : "Public key",
    },
  ];

  const actions: ListActionType<Wallet> = {
    width: "40px",
    textAlign: "center",
    renderActionCell: (params) => (
      <NavigationButton
        keepBackground={false}
        variant="text"
        pathTo={`../wallets?wallet_id=${params.data?.id}`}
      >
        <RedirectIcon />
      </NavigationButton>
    ),
  };

  return (
    <div className={styles.userData}>
      <div>
        <Text className={styles.label}>Wallet Access List</Text>
        <List
          className={styles.list}
          variant="contained"
          columns={walletColumns}
          data={rows}
          actions={actions}
          isLoading={isLoading}
          rowHeight={40}
          size="small"
        />
      </div>
      {user && (
        <div>
          <Text className={styles.label}>User Info</Text>
          <ListItem
            height={58}
            row={user}
            columns={userColumns}
            variant="outlined-free"
            className={styles.userInfo}
            type="secondary"
          />
        </div>
      )}
    </div>
  );
};

export default UserData;
