import {
  chainIdNames,
  formatCurrency,
  getUserIcon,
  transformGroups,
} from "@utils/transformers";
import { Wallet } from "../Wallets";
import {
  CopyText,
  List,
  ListColumnType,
  ListItem,
  Text,
  ChipArray,
} from "@components/common";
import styles from "./WalletData.module.css";
import {
  getGetPolicyWalletsQueryKey,
  GroupSchema,
  useGetPolicyWallets,
  useUpdatePolicyWallet,
} from "@api/__generated__/mainservice";
import {
  useGetWalletTokens,
  WalletTokenSchema,
} from "@api/__generated__/balanceservice";
import { useMemo } from "react";
import { AddUserIcon, CheckIcon, ErrorIcon, InfoIcon } from "@components/icons";
import { useLocation, useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import defaultToken from "@assets/generic-cryptocurrency.svg";

interface WalletDataProps {
  wallet?: Wallet;
  nestedType?: "primary" | "secondary";
}

interface Users {
  id: string;
  groups: GroupSchema[];
}

interface WalletToken extends WalletTokenSchema {
  id: string;
}

const WalletData = ({ wallet, nestedType = "secondary" }: WalletDataProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { data } = useGetPolicyWallets(wallet?.id ?? "");
  let chains_to_query = [wallet?.chain.id ?? ""];
  if (wallet?.chain.id == "ch-60" && wallet?.walletOptions?.chains) {
    chains_to_query = wallet?.walletOptions?.chains;
  }
  const { data: tokensData, isLoading } = useGetWalletTokens(
    wallet?.address ?? "",
    { chain_id: chains_to_query },
  );
  const { mutate: updateWalletAccess } = useUpdatePolicyWallet({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetPolicyWalletsQueryKey(wallet?.id ?? ""),
        });
      },
    },
  });

  const removeUserAccess = (userId: string) => {
    if (wallet?.id)
      updateWalletAccess({ walletId: wallet.id, data: { revoke: [userId] } });
  };

  const users: Users = useMemo(() => {
    if (!data) return { id: "someId", groups: [] };
    return { id: "someId", groups: data };
  }, [data]);

  const tokens: WalletToken[] = useMemo(() => {
    if (!tokensData) return [];
    return tokensData
      .map((token) => ({ id: token.name, ...token }))
      .filter((token) => {
        if (
          (token.balance != null || token.balance != undefined) &&
          (token?.balance == 0 || token?.balance < 0.02)
        ) {
          return false;
        }

        return true;
      });
  }, [tokensData]);

  const urlIconTokenColumns: ListColumnType<WalletToken>[] = [
    {
      field: "icon_url",
      headerName: "",
      width: "10%",
      textAlign: "center",
      renderCell: (params) => (
        <div className={styles.iconContainer}>
          <img
            width="24px"
            height="24px"
            src={
              !params.data?.icon_url || params.data?.icon_url.trim() === ""
                ? defaultToken
                : params.data?.icon_url
            }
            alt="icon"
          />
        </div>
      ),
    },
    {
      field: "ticker",
      headerName: "Token",
      width: "20%",
      renderCell: (params) => {
        const name = params.data?.ticker
          ? params.data?.ticker
          : params.data?.name;
        console.log({ name, data: params.data });
        return (
          <>
            {name}
            <small className={styles.chainSubtitle}>
              {" "}
              {params.data?.chain_id ? chainIdNames[params.data?.chain_id] : ""}
            </small>
          </>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: "30%",
    },
    {
      field: "price_usd",
      headerName: "Price",
      width: "20%",
      valueFormatter: (params) => formatCurrency("USD", params.data?.price_usd),
    },
    {
      field: "balance",
      headerName: "Balance",
      width: "20%",
      valueFormatter: (params) => formatCurrency("USD", params.data?.balance),
    },
  ];

  const coinTypeTokenColumns: ListColumnType<WalletToken>[] = [
    {
      field: "name",
      headerName: "Token",
      width: "60%",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: "40%",
    },
  ];

  const usersColumns: ListColumnType<Users>[] = [
    {
      field: "id",
      headerName: "",
      width: "100%",
      renderCell: (params) => (
        <ChipArray
          chips={transformGroups(params.data?.groups ?? []).map((x) => ({
            startIcon: getUserIcon(x.type),
            label: x.name,
            value: x.id,
          }))}
          noDataMessage="No users"
          addIcon={<AddUserIcon />}
          onAdd={() =>
            navigate(`${wallet?.id}/adduser`, {
              state: { backgroundLocation: location },
            })
          }
          onRemove={(value) => removeUserAccess(value)}
        />
      ),
    },
  ];
  const balanceDisclaimer = () => {
    const hoverText = (
      <>
        <b className={styles.boldDisclaimer}>Disclaimer:</b> Balances and fiat
        conversions displayed are for informational purposes only and may not
        reflect precise values due to reliance on an external service provider.
        While we strive for accuracy, these figures should not be used as the
        sole basis for trading decisions. Before taking any business critical
        decision, we recommend consulting an additional service for
        verification.
      </>
    );
    return <InfoIcon hoverElement={hoverText} />;
  };

  function dataColumns(tokens: WalletToken[]): ListColumnType<WalletToken>[] {
    if (tokens?.[0]?.icon_url) {
      return urlIconTokenColumns;
    } else {
      return coinTypeTokenColumns;
    }
  }

  return (
    <div className={styles.walletData}>
      <div className={styles.grid}>
        <div className={styles.col}>
          <Text className={styles.labelTitle}>
            <>Token Info {balanceDisclaimer()}</>
          </Text>
          <List
            isLoading={isLoading}
            className={styles.walletTokens}
            variant="contained"
            columns={dataColumns(tokens)}
            data={tokens}
            rowHeight={40}
            size="small"
            type={nestedType}
          />
        </div>
        {wallet && (
          <div className={styles.col}>
            <Text className={styles.labelTitle}>Wallet Details</Text>
            <div
              className={classNames(styles.walletDetails, {
                [styles.primary]: nestedType === "primary",
                [styles.secondary]: nestedType === "secondary",
              })}
            >
              <div>
                <div className={styles.label}>State</div>
                {wallet.state === "active" ? (
                  <div className={styles.activeStatusCell}>
                    active
                    <CheckIcon />
                  </div>
                ) : (
                  <div className={styles.inactiveStatusCell}>
                    inactive
                    <ErrorIcon />
                  </div>
                )}
              </div>
              <div>
                <div className={styles.label}>Name</div>
                <div className={styles.value}>{wallet.name}</div>
              </div>
              <div>
                <div className={styles.label}>Wallet ID</div>
                <div className={styles.value}>{wallet.id}</div>
              </div>
              <div>
                <div className={styles.label}>Address</div>
                <CopyText
                  className={styles.value}
                  confirmationMessage="Wallet address copied to clipboard!"
                >
                  {wallet.address}
                </CopyText>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <Text className={styles.label}>Users</Text>
        <ListItem
          className={styles.users}
          height={80}
          row={users}
          columns={usersColumns}
          variant="outlined"
          type={nestedType}
        />
      </div>
    </div>
  );
};

export default WalletData;
