import { PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./InputAdornment.module.css";

interface InputAdornmentProps {
  onClick?: () => void;
  className?: string;
  disablePointer?: boolean;
}

export const InputAdornment = ({
  children,
  onClick,
  className,
  disablePointer,
}: PropsWithChildren<InputAdornmentProps>) => {
  return (
    <span
      onClick={onClick}
      className={classNames(styles.icon, className, {
        [styles.noPointer]: disablePointer,
      })}
    >
      {children}
    </span>
  );
};
