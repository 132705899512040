import { NavigationButton, Title, SearchBar } from "@components/common";
import { PlusIcon } from "@components/icons";
import styles from "./UserHeader.module.css";

interface Props {
  onSearch?: (search: string) => void;
}

const UserHeader = ({ onSearch }: Props) => {
  const handleSearch = (search: string) => {
    if (onSearch) onSearch(search);
  };

  return (
    <div className={styles.headerContainer}>
      <Title className={styles.title}>Users</Title>
      <div className={styles.actionContainer}>
        <SearchBar
          className={styles.searchBar}
          onChange={handleSearch}
          onSearch={handleSearch}
          placeholder="Search by name"
        />
        <NavigationButton
          startIcon={<PlusIcon />}
          pathTo="add"
          className={styles.addUserBtn}
          keepBackground={true}
        >
          Add user
        </NavigationButton>
      </div>
    </div>
  );
};

export default UserHeader;
