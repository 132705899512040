import { NavigationButton, Title } from "@components/common";
import styles from "./CustodianIntegration.module.css";
import {
  getCustodianDescription,
  getCustodianLogoSrc,
} from "@utils/constants/custodian";

interface Custodian {
  id: string;
  name: string;
  activated: boolean;
}

interface CustodianIntegrationProps {
  custodian: Custodian;
  className?: string;
}

const CustodianIntegration = ({
  custodian,
  className,
}: CustodianIntegrationProps) => {
  return (
    <div key={custodian.id} className={className}>
      <div className={styles.titleContainer}>
        <img
          className={styles.logo}
          src={getCustodianLogoSrc(custodian.id)}
          alt="logo"
        />
        <Title className={styles.title} size="medium">
          {custodian.name}
        </Title>
      </div>
      <div className={styles.description}>
        {getCustodianDescription(custodian.id)}
      </div>
      {custodian.activated ? (
        <div className={styles.connectedContainer}>
          <div className={styles.connected}>Connected</div>
          <NavigationButton
            className={styles.connectBtn}
            variant="text"
            keepBackground
            pathTo={`custodians/${custodian.id}/reset`}
          >
            Reset
          </NavigationButton>
        </div>
      ) : (
        <NavigationButton
          className={styles.connectBtn}
          variant="outlined"
          keepBackground
          pathTo={`custodians/${custodian.id}/add`}
        >
          Connect
        </NavigationButton>
      )}
    </div>
  );
};

export default CustodianIntegration;
