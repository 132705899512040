/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Main Service API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AddWalletRequestSchema,
  EditWalletBody,
  ListWalletsParams,
  WalletSchema,
} from ".././schemas";
import { customInstance } from "../../../api";
import type { ErrorType } from "../../../api";

/**
 * @summary Lists or finds wallets
 */
export const listWallets = (
  params?: ListWalletsParams,
  signal?: AbortSignal,
) => {
  return customInstance<WalletSchema[]>({
    url: `/wallets`,
    method: "GET",
    params,
    signal,
  });
};

export const getListWalletsQueryKey = (params?: ListWalletsParams) => {
  return [`/wallets`, ...(params ? [params] : [])] as const;
};

export const getListWalletsQueryOptions = <
  TData = Awaited<ReturnType<typeof listWallets>>,
  TError = ErrorType<void>,
>(
  params?: ListWalletsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listWallets>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListWalletsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listWallets>>> = ({
    signal,
  }) => listWallets(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listWallets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListWalletsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listWallets>>
>;
export type ListWalletsQueryError = ErrorType<void>;

export function useListWallets<
  TData = Awaited<ReturnType<typeof listWallets>>,
  TError = ErrorType<void>,
>(
  params: undefined | ListWalletsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listWallets>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listWallets>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useListWallets<
  TData = Awaited<ReturnType<typeof listWallets>>,
  TError = ErrorType<void>,
>(
  params?: ListWalletsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listWallets>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listWallets>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useListWallets<
  TData = Awaited<ReturnType<typeof listWallets>>,
  TError = ErrorType<void>,
>(
  params?: ListWalletsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listWallets>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Lists or finds wallets
 */

export function useListWallets<
  TData = Awaited<ReturnType<typeof listWallets>>,
  TError = ErrorType<void>,
>(
  params?: ListWalletsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listWallets>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListWalletsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Creates a new wallet
 */
export const createWallet = (
  addWalletRequestSchema: AddWalletRequestSchema,
) => {
  return customInstance<WalletSchema>({
    url: `/wallets`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: addWalletRequestSchema,
  });
};

export const getCreateWalletMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createWallet>>,
    TError,
    { data: AddWalletRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createWallet>>,
  TError,
  { data: AddWalletRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createWallet>>,
    { data: AddWalletRequestSchema }
  > = (props) => {
    const { data } = props ?? {};

    return createWallet(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateWalletMutationResult = NonNullable<
  Awaited<ReturnType<typeof createWallet>>
>;
export type CreateWalletMutationBody = AddWalletRequestSchema;
export type CreateWalletMutationError = ErrorType<void>;

/**
 * @summary Creates a new wallet
 */
export const useCreateWallet = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createWallet>>,
    TError,
    { data: AddWalletRequestSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createWallet>>,
  TError,
  { data: AddWalletRequestSchema },
  TContext
> => {
  const mutationOptions = getCreateWalletMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns details about a specific wallet
 */
export const getWallet = (walletId: string, signal?: AbortSignal) => {
  return customInstance<WalletSchema>({
    url: `/wallets/${walletId}`,
    method: "GET",
    signal,
  });
};

export const getGetWalletQueryKey = (walletId: string) => {
  return [`/wallets/${walletId}`] as const;
};

export const getGetWalletQueryOptions = <
  TData = Awaited<ReturnType<typeof getWallet>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWalletQueryKey(walletId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWallet>>> = ({
    signal,
  }) => getWallet(walletId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!walletId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetWalletQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWallet>>
>;
export type GetWalletQueryError = ErrorType<void>;

export function useGetWallet<
  TData = Awaited<ReturnType<typeof getWallet>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWallet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWallet<
  TData = Awaited<ReturnType<typeof getWallet>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWallet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWallet<
  TData = Awaited<ReturnType<typeof getWallet>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Returns details about a specific wallet
 */

export function useGetWallet<
  TData = Awaited<ReturnType<typeof getWallet>>,
  TError = ErrorType<void>,
>(
  walletId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetWalletQueryOptions(walletId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Updates the information about a wallet.
 */
export const editWallet = (
  walletId: string,
  editWalletBody: EditWalletBody,
) => {
  return customInstance<WalletSchema>({
    url: `/wallets/${walletId}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: editWalletBody,
  });
};

export const getEditWalletMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editWallet>>,
    TError,
    { walletId: string; data: EditWalletBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editWallet>>,
  TError,
  { walletId: string; data: EditWalletBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editWallet>>,
    { walletId: string; data: EditWalletBody }
  > = (props) => {
    const { walletId, data } = props ?? {};

    return editWallet(walletId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditWalletMutationResult = NonNullable<
  Awaited<ReturnType<typeof editWallet>>
>;
export type EditWalletMutationBody = EditWalletBody;
export type EditWalletMutationError = ErrorType<void>;

/**
 * @summary Updates the information about a wallet.
 */
export const useEditWallet = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editWallet>>,
    TError,
    { walletId: string; data: EditWalletBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof editWallet>>,
  TError,
  { walletId: string; data: EditWalletBody },
  TContext
> => {
  const mutationOptions = getEditWalletMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Inactivates a wallet, it becomes unusable by users. The wallet can be activated again with a PATCH command.
 */
export const deleteWallet = (walletId: string) => {
  return customInstance<WalletSchema>({
    url: `/wallets/${walletId}`,
    method: "DELETE",
  });
};

export const getDeleteWalletMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteWallet>>,
    TError,
    { walletId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteWallet>>,
  TError,
  { walletId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteWallet>>,
    { walletId: string }
  > = (props) => {
    const { walletId } = props ?? {};

    return deleteWallet(walletId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteWalletMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteWallet>>
>;

export type DeleteWalletMutationError = ErrorType<void>;

/**
 * @summary Inactivates a wallet, it becomes unusable by users. The wallet can be activated again with a PATCH command.
 */
export const useDeleteWallet = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteWallet>>,
    TError,
    { walletId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteWallet>>,
  TError,
  { walletId: string },
  TContext
> => {
  const mutationOptions = getDeleteWalletMutationOptions(options);

  return useMutation(mutationOptions);
};
