import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import qs from "qs";

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    cancelToken: source.token,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  }).then(({ data }) => data);

  promise.cancel = () => {
    source.cancel("Query was cancelled by Vue Query");
  };

  return promise;
};

export default customInstance;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
