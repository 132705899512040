import { ReactElement, useCallback } from "react";

import styles from "./Cell.module.css";
import {
  ListCellValue,
  ListColumnType,
  DefaultListObjectType,
} from "./List.types";

interface Props<ListObjectType extends DefaultListObjectType> {
  height: number;
  row: ListObjectType;
  column: ListColumnType<ListObjectType>;
}

/**
 * Table component
 * @param params With all properties  from [[TableProps]]
 * @returns ReactElement
 */
export const Cell = <ListObjectType extends DefaultListObjectType>({
  height,
  row,
  column,
}: Props<ListObjectType>): ReactElement => {
  const getCellValue = useCallback(
    (column: ListColumnType<ListObjectType>, row: ListObjectType) => {
      if (column.renderCell)
        return column.renderCell({
          field: column.field,
          value: row?.[column.field] as ListCellValue,
          data: row,
        });
      else if (column?.valueFormatter)
        return column?.valueFormatter({
          field: column.field,
          value: row?.[column.field] as ListCellValue,
          data: row,
        });
      else return row?.[column.field];
    },
    [],
  );

  return (
    <div
      style={{
        minHeight: `${height}px`,
        ...(column.width ? { width: `${column.width}` } : {}),
        ...(column.textAlign ? { textAlign: column.textAlign } : {}),
      }}
      className={styles.cell}
    >
      {column.headerName && (row?.[column.field] || column.renderCell) && (
        <div className={styles.label}>{column.headerName}</div>
      )}
      {getCellValue(column, row) as ReactElement}
    </div>
  );
};
