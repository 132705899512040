import { ReactElement, useEffect, useState } from "react";
import { Input } from "../input/Input";
import { ClearIcon, SearchIcon } from "@components/icons";
import { InputAdornment } from "../input/InputAdornment";
import styles from "./SearchBar.module.css";

interface Props {
  className?: string;
  error?: boolean;
  isDisabled?: boolean;
  onSearch: (search: string) => void;
  onChange?: (search: string) => void;
  placeholder?: string;
  value?: string;
}

export const SearchBar = ({
  value,
  onSearch,
  onChange,
  ...props
}: Props): ReactElement => {
  const [search, setSearch] = useState<string>("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(search);
    }
  };

  const handleChange = (search: string) => {
    setSearch(search);
    if (onChange) onChange(search);
  };

  const handleClear = () => {
    setSearch("");
    onSearch("");
  };

  useEffect(() => {
    setSearch(value ?? "");
  }, [value]);

  return (
    <Input
      value={search}
      onKeyDown={(evt) => handleKeyDown(evt)}
      onChange={(evt) => handleChange(evt.target.value)}
      {...props}
      startAdornment={
        <InputAdornment onClick={() => onSearch(search)} disablePointer={true}>
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        search.trim() !== "" && (
          <div className={styles.clear}>
            <InputAdornment onClick={handleClear}>
              <ClearIcon />
            </InputAdornment>
          </div>
        )
      }
    />
  );
};
