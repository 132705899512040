/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Balance Service API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { GetWalletTokensParams, WalletTokenSchema } from ".././schemas";
import { customInstance } from "../../../api";
import type { ErrorType } from "../../../api";

/**
 * @summary Returns tokens on the wallet
 */
export const getWalletTokens = (
  address: string,
  params: GetWalletTokensParams,
  signal?: AbortSignal,
) => {
  return customInstance<WalletTokenSchema[]>({
    url: `/wallets/${address}/tokens`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetWalletTokensQueryKey = (
  address: string,
  params: GetWalletTokensParams,
) => {
  return [`/wallets/${address}/tokens`, ...(params ? [params] : [])] as const;
};

export const getGetWalletTokensQueryOptions = <
  TData = Awaited<ReturnType<typeof getWalletTokens>>,
  TError = ErrorType<void>,
>(
  address: string,
  params: GetWalletTokensParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getWalletTokens>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetWalletTokensQueryKey(address, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWalletTokens>>> = ({
    signal,
  }) => getWalletTokens(address, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!address,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getWalletTokens>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetWalletTokensQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWalletTokens>>
>;
export type GetWalletTokensQueryError = ErrorType<void>;

export function useGetWalletTokens<
  TData = Awaited<ReturnType<typeof getWalletTokens>>,
  TError = ErrorType<void>,
>(
  address: string,
  params: GetWalletTokensParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getWalletTokens>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWalletTokens>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWalletTokens<
  TData = Awaited<ReturnType<typeof getWalletTokens>>,
  TError = ErrorType<void>,
>(
  address: string,
  params: GetWalletTokensParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getWalletTokens>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWalletTokens>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWalletTokens<
  TData = Awaited<ReturnType<typeof getWalletTokens>>,
  TError = ErrorType<void>,
>(
  address: string,
  params: GetWalletTokensParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getWalletTokens>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Returns tokens on the wallet
 */

export function useGetWalletTokens<
  TData = Awaited<ReturnType<typeof getWalletTokens>>,
  TError = ErrorType<void>,
>(
  address: string,
  params: GetWalletTokensParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getWalletTokens>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetWalletTokensQueryOptions(address, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
