import {
  getListUsersQueryKey,
  useCreateUser,
} from "@api/__generated__/mainservice";
import { Title, Button, Modal, RadioGroup, TextArea } from "@components/common";
import styles from "./AddUser.module.css";
import { TextField } from "@components/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

interface AddUserForm {
  name: string;
  type: string;
  public_key?: string;
  email?: string;
}

const AddUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createUser, isPending } = useCreateUser({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getListUsersQueryKey(),
        });
        navigate(-1);
      },
      onError: () => {
        enqueueSnackbar("Failed to add user.", { variant: "error" });
      },
    },
  });

  const methods = useForm<AddUserForm>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().trim().required("Name is required"),
        email: Yup.string()
          .trim()
          .test(
            "required-as-trader",
            "Email is required for a trader",
            (value, context) =>
              context.parent["type"] === "BOT" || value?.trim() !== "",
          )
          .test(
            "email-format",
            "Email must be a valid email",
            (value, context) =>
              context.parent["type"] === "BOT" ||
              Yup.string().trim().email().isValid(value),
          ),
        public_key: Yup.string()
          .trim()
          .test(
            "required-as-bot",
            "Public key is required for a bot",
            (value, context) =>
              context.parent["type"] === "TRADER" || value?.trim() !== "",
          ),
        type: Yup.string().trim().required(),
      }),
    ),
    defaultValues: {
      name: "",
      type: "TRADER",
    },
    mode: "onChange",
  });

  const userType = methods.watch("type") as string;

  const onSubmit = (values: AddUserForm) => {
    if (values.type === "TRADER" && values.email) {
      createUser({
        data: { name: values.name, email: values.email, type: values.type },
      });
    }
    if (values.type === "BOT" && values.public_key) {
      createUser({
        data: {
          name: values.name,
          public_key: values.public_key,
          type: values.type,
        },
      });
    }
  };

  return (
    <Modal className={styles.card}>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Title underlined>Add User</Title>
          <div className={styles.formContent}>
            <RadioGroup
              className={styles.radioInputContainer}
              name="type"
              options={[
                { value: "TRADER", label: "Trader" },
                { value: "BOT", label: "Bot" },
              ]}
            />
            <TextField
              variant="underlined"
              className={styles.textInput}
              placeholder="Name"
              name="name"
            />
            {userType === "TRADER" && (
              <TextField
                variant="underlined"
                className={styles.textInput}
                placeholder="Email"
                name="email"
              />
            )}
            {userType === "BOT" && (
              <TextArea
                className={styles.textAreaInput}
                placeholder="Public ECDSA key"
                name="public_key"
              />
            )}
            <Button
              disabled={isPending}
              className={styles.submitBtn}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddUser;
