import { useListWallets } from "@api/__generated__/mainservice";
import {
  Loader,
  List,
  ListColumnType,
  HamburgerMenu,
  ListActionType,
  CopyText,
  NavigationButton,
} from "@components/common";
import { useMemo, useState } from "react";
import { getChainIcon } from "@utils/transformers";
import styles from "./Wallets.module.css";
import WalletHeader from "./WalletHeader";
import WalletData from "./ChainData/WalletData";
import { TransferIcon } from "@components/icons";
import { useLocation, useNavigate } from "react-router";
import {
  getCustodianLogoSrc,
  isWalletNative,
} from "@utils/constants/custodian";
import LinkedWalletData from "./ChainData/LinkedWalletData";
import { LinkedWalletSchema } from "@api/__generated__/mainservice/schemas/custody-router";

export interface Chain {
  id: string;
  name: string;
}

export interface WalletOptions {
  keyAlgo?: string;
  chains?: string[];
}

export interface Wallet {
  id: string;
  chain: Chain;
  name: string;
  address: string;
  state: string;
  custodianId: string;
  walletOptions?: WalletOptions;
}

const Wallets = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //    const { data: chains, isLoading } = useGetChains();
  const { data: walletData, isLoading } = useListWallets();
  const [filteredWallets, setFilteredWallets] = useState<Wallet[]>([]);

  const wallets: Wallet[] = useMemo(() => {
    const result =
      walletData?.map((x) => {
        return {
          id: x.wallet_id,
          walletOptions: {
            keyAlgo: x?.wallet_options?.key_algorithm,
            chains: x?.wallet_options?.chains,
          },
          chain: { id: x.chain.chain_id, name: x.chain.name },
          name: x.name,
          address: x.address,
          state: x.state,
          custodianId: (x as LinkedWalletSchema)["custodian"]?.id ?? "cd-000",
        };
      }) ?? [];
    setFilteredWallets(result);
    return result;
  }, [walletData]);

  const columns: ListColumnType<Wallet>[] = useMemo(
    () => [
      {
        field: "custodianId",
        headerName: "",
        width: "60px",
        renderCell: (params) => (
          <div className={styles.custodianLogoContainer}>
            <img
              alt="custodian logo"
              className={styles.custodianLogo}
              src={getCustodianLogoSrc(params.data?.custodianId)}
            />
          </div>
        ),
      },
      {
        field: "chain",
        headerName: "",
        width: "120px",
        renderCell: (params) => (
          <div className={styles.chain}>
            <div className={styles.iconSmall}>
              {getChainIcon(params.data?.chain.name ?? "")}
            </div>
            {params.data?.chain.name}
          </div>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        width: "50%",
      },
      {
        field: "address",
        headerName: "Address",
        width: "50%",
        renderCell: (params) => (
          <CopyText
            className={styles.walletAddress}
            copyValue={params.data?.address}
            confirmationMessage="Wallet address copied to clipboard!"
          >
            {params.data?.address.slice(0, 10) +
              " ...... " +
              params.data?.address.slice(-10)}
          </CopyText>
        ),
      },
    ],
    [],
  );

  const actions: ListActionType<Wallet> = useMemo(
    () => ({
      width: "40px",
      textAlign: "center",
      renderActionCell: (params) => (
        <>
          {isWalletNative(params.data?.id) ? (
            <HamburgerMenu
              id={params.data?.id}
              options={[
                {
                  className:
                    params.data?.state === "active"
                      ? styles.deactivate
                      : styles.activate,
                  label:
                    params.data?.state === "active" ? "Deactivate" : "Activate",
                  onClick: () => {
                    navigate(
                      `${params.data?.id}/${params.data?.state === "active" ? "deactivate" : "activate"}`,
                      { state: { backgroundLocation: location } },
                    );
                  },
                },
              ]}
            />
          ) : (
            <NavigationButton
              className={styles.transferBtn}
              variant="text"
              keepBackground
              pathTo={`${params.data?.id}/transfer`}
            >
              <TransferIcon />
            </NavigationButton>
          )}
        </>
      ),
    }),
    [navigate],
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <List
          className={styles.list}
          variant="outlined-free"
          rowHeight={100}
          header={
            <WalletHeader
              allWallets={wallets}
              filterWallets={setFilteredWallets}
            />
          }
          columns={columns}
          data={filteredWallets}
          content={{
            renderContent: (params) => (
              <>
                {isWalletNative(params.data?.id) ? (
                  <WalletData wallet={params.data} nestedType="primary" />
                ) : (
                  <LinkedWalletData
                    walletId={params.data?.id}
                    nestedType="primary"
                  />
                )}
              </>
            ),
          }}
          actions={actions}
        />
      )}
    </>
  );
};

export default Wallets;
