import { useMemo, useState } from "react";
import { Routing } from "../../RouteInterfaces";
import { useLocation } from "react-router";
import SideBarButton from "./SideBarButton";
import styles from "./SideBar.module.css";
import logo from "@assets/logo.svg";
import classNames from "classnames";
import { IconButton } from "@components/common";
import { ChevronLeftIcon, ChevronRightIcon } from "@components/icons";

const SideBar = ({ routes }: Routing) => {
  const [condensedSideBar, setCondensedSideBar] = useState<boolean>(false);

  const location = useLocation();

  const elements = useMemo(() => {
    const routesToShow = routes.filter((route) => !route.hidden);
    return routesToShow.map((route) => {
      return (
        <SideBarButton
          key={route.path}
          icon={route.icon}
          value={route.name}
          pathTo={route.path}
          selected={location.pathname.startsWith(`/${route.path}`)}
        />
      );
    });
  }, [routes, location]);

  const condensedElements = useMemo(() => {
    const routesToShow = routes.filter((route) => !route.hidden);
    return routesToShow.map((route) => {
      return (
        <SideBarButton
          condensed={true}
          key={route.path}
          icon={route.icon}
          value={route.name}
          pathTo={route.path}
          selected={location.pathname.startsWith(`/${route.path}`)}
        />
      );
    });
  }, [routes, location]);

  return (
    <div
      className={classNames(styles.sidebarContainer, {
        [styles.condensedContainer]: condensedSideBar,
      })}
    >
      <div
        className={classNames(styles.sidebarHeader, {
          [styles.condensedHeader]: condensedSideBar,
        })}
      >
        <img
          className={classNames(styles.logo, {
            [styles.condensedLogo]: condensedSideBar,
          })}
          src={logo}
          alt="logo"
        />
        {!condensedSideBar && (
          <div className={styles.sidebarTitle}>CrypDefi</div>
        )}
      </div>
      {condensedSideBar ? condensedElements : elements}
      <IconButton
        className={classNames(styles.condenseBtn, {
          [styles.expand]: condensedSideBar,
          [styles.collapse]: !condensedSideBar,
        })}
        onClick={() => setCondensedSideBar((prev) => !prev)}
      >
        {condensedSideBar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </div>
  );
};

export default SideBar;
