import { PropsWithChildren } from "react";

import styles from "./CopyText.module.css";
import classNames from "classnames";
import { CopyIcon } from "@components/icons";
import { useSnackbar } from "notistack";

interface Props {
  className?: string;
  copyValue?: string;
  confirmationMessage?: string;
}

export const CopyText = ({
  children,
  className,
  copyValue,
  confirmationMessage,
}: PropsWithChildren<Props>) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = () => {
    if (copyValue) {
      navigator.clipboard
        .writeText(copyValue)
        .then(() => {
          enqueueSnackbar(confirmationMessage ?? "Text copied to clipboard");
        })
        .catch(() => {
          enqueueSnackbar("Failed to copy text: ", { variant: "error" });
        });
      return;
    }
    if (typeof children === "string") {
      navigator.clipboard
        .writeText(children)
        .then(() => {
          enqueueSnackbar(confirmationMessage ?? "Text copied to clipboard");
        })
        .catch(() => {
          enqueueSnackbar("Failed to copy text: ", { variant: "error" });
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.text, className)}>{children}</div>
      <div className={styles.copy} onClick={handleCopyClick}>
        <CopyIcon />
      </div>
    </div>
  );
};
