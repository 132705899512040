import Lottie from "react-lottie";
import loaderAnimation from "@assets/lotties/loader-lottie.json";

const sizeVariants = {
  small: "small",
  medium: "medium",
  large: "large",
};

const sizeMap = new Map<keyof typeof sizeVariants, number>([
  ["small", 100],
  ["medium", 200],
  ["large", 400],
]);

interface Props {
  size?: keyof typeof sizeVariants;
}

export const Loader = ({ size = "large" }: Props) => {
  return (
    <Lottie
      height={sizeMap.get(size)}
      width={sizeMap.get(size)}
      options={{
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
    />
  );
};
