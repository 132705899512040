import { GroupSchema } from "@api/__generated__/mainservice";
import {
  AptosIcon,
  BotIcon,
  EthereumIcon,
  GroupIcon,
  PolygonIcon,
  RadixIcon,
  TraderIcon,
  AvalancheIcon,
  ArbitrumIcon,
  BnbIcon,
} from "@components/icons";

export const getChainIcon = (chainName: string) => {
  if (chainName === "Mainnet") return <EthereumIcon />;
  if (chainName === "EVM") return <EthereumIcon />;
  if (chainName === "Aptos") return <AptosIcon />;
  if (chainName === "Radix") return <RadixIcon />;
  if (chainName === "Polygon") return <PolygonIcon />;
  if (chainName === "Avalanche") return <AvalancheIcon />;
  if (chainName === "Arbitrum") return <ArbitrumIcon />;
  if (chainName === "BNB") return <BnbIcon />;
  return <>{chainName}</>;
};

type StringObject = {
  [key: string]: string;
};
export const chainIdNames: StringObject = {
  "ch-1022": "Radix",
  "ch-637": "Aptos",
  "ch-60": "EVM",
  "ch-60-1": "Mainnet",
  "ch-60-137": "Polygon",
  "ch-60-43114": "Avalanche",
  "ch-60-42161": "Arbitrum",
  "ch-60-56": "BNB",
};

export const getUserIcon = (userType: string) => {
  if (userType === "BOT") return <BotIcon />;
  if (userType === "TRADER") return <TraderIcon />;
  if (userType === "GROUP") return <GroupIcon />;
  return <></>;
};

export const formatCurrency = (currency: string, amount?: number) => {

  if (amount === undefined) return undefined;

  const rounded = Math.round(amount * 100) / 100;

  return rounded.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
  });
};

interface UserOrGroup {
  type: "GROUP" | "TRADER" | "BOT";
  name: string;
  id: string;
}
export const transformGroups = (groups: GroupSchema[]): UserOrGroup[] => {
  const result: UserOrGroup[] = [];
  groups.forEach((group) => {
    if (group.group_id === "-") {
      result.push(
        ...group.members.map((member) => ({
          type: member.type,
          name: member.name,
          id: member.user_id,
        })),
      );
    } else {
      result.push({ type: "GROUP", name: group.name, id: group.group_id });
    }
  });
  return result;
};

export const snakeToTitleCase = (str: string): string => {
  return str
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());
};
