import React, { useRef } from "react";
import classNames from "classnames";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

import { Input, InputVariants } from "../Input";
import { InputMessage } from "../InputMessage";
import styles from "./NumberField.module.css";
import { Text } from "@components/common/text";

export interface NumberFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: keyof typeof InputVariants;
  className?: string;
  description?: string;
  errorMessage?: string;
  showLabel?: boolean;
}

export const InnerNumberField = React.forwardRef<
  HTMLInputElement,
  NumberFieldProps
>(({ className, variant = "naked", ...rest }, forwardedRef) => {
  const internalRef = useRef<HTMLInputElement | null>(null);
  const ref = forwardedRef || internalRef;

  return (
    <div
      className={classNames(styles.inputContainer, className, {
        [styles.disabled]: rest.disabled,
      })}
    >
      <div className={styles.inputField}>
        {rest.showLabel && (
          <Text
            className={classNames(styles.label, {
              [styles[variant]]: variant,
            })}
          >
            {rest.placeholder}
          </Text>
        )}
        <Input
          ref={ref}
          variant={variant}
          className={classNames(className, {
            [styles.inputError]: rest.errorMessage,
          })}
          {...rest}
        />
      </div>
      <InputMessage message={rest.description} />
      <InputMessage variant="error" message={rest.errorMessage} />
    </div>
  );
});

export const NumberField = ({
  name,
  ...otherProps
}: UseControllerProps & NumberFieldProps) => {
  const context = useFormContext();
  if (context === undefined || context === null) {
    throw new Error(
      "NumberField must be used within a FormProvider from react-hook-form",
    );
  }

  const { control } = context;

  const sanitizeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    prev: string,
  ) => {
    const value = event.target.value;

    if (value.trim() === "") {
      return undefined;
    }

    // Allow only integers (using regex to filter non-digit characters)
    if (!/^\d*\.?\d*$/.test(value)) {
      return prev;
    }

    // Convert the value to an integer
    let numberValue = parseFloat(value);

    // Ensure the value is a number
    if (isNaN(numberValue)) {
      return prev;
    }

    if (
      otherProps.min !== undefined &&
      numberValue < parseFloat(otherProps.min.toString())
    ) {
      numberValue = parseFloat(otherProps.min.toString());
      return numberValue.toString();
    }

    // Enforce the maxValue constraint if provided
    if (
      otherProps.max !== undefined &&
      numberValue > parseFloat(otherProps.max.toString())
    ) {
      numberValue = parseFloat(otherProps.max.toString());
      return numberValue.toString();
    }

    return value;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <InnerNumberField
            {...otherProps}
            {...field}
            {...control.register(name, {
              onChange: (evt) => {
                const value = sanitizeInput(evt, field.value as string);
                context.setValue(name, value);
              },
            })}
            errorMessage={
              fieldState.error
                ? otherProps.errorMessage || fieldState.error.message
                : undefined
            }
            showLabel={field.value !== undefined}
          />
        );
      }}
    />
  );
};

NumberField.displayName = "NumberField";
