import { PropsWithChildren, ReactNode } from "react";

import styles from "./Card.module.css";
import classNames from "classnames";

interface HeaderProps {
  value: string;
  endAdornment?: ReactNode;
}

interface Props {
  header?: HeaderProps;
  className?: string;
}

export const Card = ({
  header,
  children,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.card, className)}>
      {header && (
        <div className={styles.cardHeader}>
          {header.value}
          {header.endAdornment && header.endAdornment}
        </div>
      )}
      <div className={styles.cardContent}>{children}</div>
    </div>
  );
};
